import "../styles.scss";

import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

import { LuArrowDownRight, LuArrowUpRight } from "react-icons/lu";

import goldTrade from "../../../../assets/images/Untitledesign.png";
import { useTranslation } from "react-i18next";

function Section1(props: any) {
  const { t } = useTranslation();
  const liveRate = useSelector((state: any) => state.liveRate.current);
  const prevRate = useSelector((state: any) => state.liveRate.prev);
  const current = useSelector((state: any) => state.currencyInfo.current);

  const getTextColor = (type: any) => {
    const findLiveIndex = liveRate.find((item: any) => item.metal === type);
    const findPrevIndex = prevRate.find((item: any) => item.metal === type);

    if (findLiveIndex?.totalBuyAmount > findPrevIndex?.totalBuyAmount) {
      return "#50C878";
    } else if (findLiveIndex?.totalBuyAmount < findPrevIndex?.totalBuyAmount) {
      return "#D2042D";
    } else {
    }
  };

  const calculateLiveRate = (
    type: string,
    metalName: string,
    weightIn?: any
  ) => {
    const findLiverate = liveRate.find(
      (item: any) => item?.metal === metalName
    );
    if (!findLiverate || !current?.exchange_rate) {
      return "0.00";
    }

    let rate =
      (type === "buy"
        ? findLiverate?.totalBuyAmount
        : findLiverate?.totalSellAmount) * Number(current?.exchange_rate);

    if (weightIn) {
      rate *= Number(weightIn);
    }

    rate = Number(rate.toFixed(2));

    if (isNaN(rate)) {
      return "0.00";
    }

    return rate.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className="app-HomeScreen-Section1">
      <div className="app-HomeScreen-Section1-Bo2">
        <Row className="g-0 w-100 h-100">
          <Col md={12}>
            <div className="app-HomeScreen-Section1-Head">
              <img src={goldTrade} />
              <div>
                {t("SPORT_RATE_AED")}
                <br />
                <span className="app-HomeScreen-Section1-txt2">
                  {t("gold_silver")}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div style={{ flex: 1 }}></div>
      <div className="app-HomeScreen-Section1-Bo3">
        <Row className="g-0 w-100 h-100 ">
          <Col md={{ span: 2, offset: 2 }} xs={6}>
            <div className="app-HomeScreen-Section1-Box1">
              <div className="app-HomeScreen-Section1-txt2">
                {t("gold")} 9999&nbsp;
                <span className="app-HomeScreen-Section1-txt4">GM</span>
              </div>
              <div
                className="app-HomeScreen-Section1-txt1"
                style={{
                  color: getTextColor("GOLD 9999"),
                }}
              >
                {calculateLiveRate(props?.type, "GOLD 9999")}
                {getTextColor("GOLD 9999") === "#D2042D" ? (
                  <LuArrowDownRight />
                ) : getTextColor("GOLD 9999") === "#50C878" ? (
                  <LuArrowUpRight />
                ) : null}
              </div>
            </div>
          </Col>
          <Col md={2} xs={6}>
            <div className="app-HomeScreen-Section1-Box1 ">
              <div className="app-HomeScreen-Section1-txt2">
                {t("gold")} 9999 &nbsp;
                <span className="app-HomeScreen-Section1-txt4">KG</span>
              </div>
              <div
                className="app-HomeScreen-Section1-txt1"
                style={{
                  color: getTextColor("GOLD 9999"),
                }}
              >
                {calculateLiveRate(props?.type, "GOLD 9999", 1000)}
                {getTextColor("GOLD 9999") === "#D2042D" ? (
                  <LuArrowDownRight />
                ) : getTextColor("GOLD 9999") === "#50C878" ? (
                  <LuArrowUpRight />
                ) : null}
              </div>
            </div>
          </Col>
          <Col md={2} xs={6}>
            <div className="app-HomeScreen-Section1-Box1 ">
              <div className="app-HomeScreen-Section1-txt2">
                {t("gold")} 995&nbsp;
                <span className="app-HomeScreen-Section1-txt4">GM</span>
              </div>
              <div
                className="app-HomeScreen-Section1-txt1"
                style={{
                  color: getTextColor("GOLD 995"),
                }}
              >
                {calculateLiveRate(props?.type, "GOLD 995")}{" "}
                {getTextColor("GOLD 995") === "#D2042D" ? (
                  <LuArrowDownRight />
                ) : getTextColor("GOLD 995") === "#50C878" ? (
                  <LuArrowUpRight />
                ) : null}
              </div>
            </div>
          </Col>
          <Col md={2} xs={6}>
            <div className="app-HomeScreen-Section1-Box1 ">
              <div className="app-HomeScreen-Section1-txt2">
                {t("gold")} 995&nbsp;
                <span className="app-HomeScreen-Section1-txt4">KG</span>
              </div>
              <div
                className="app-HomeScreen-Section1-txt1"
                style={{
                  color: getTextColor("GOLD 995"),
                }}
              >
                {calculateLiveRate(props?.type, "GOLD 995", 1000)}
                {getTextColor("GOLD 995") === "#D2042D" ? (
                  <LuArrowDownRight />
                ) : getTextColor("GOLD 995") === "#50C878" ? (
                  <LuArrowUpRight />
                ) : null}
              </div>
            </div>
          </Col>
          <Col md={2} xs={6}>
            <div className="app-HomeScreen-Section1-Box1 Last">
              <div className="app-HomeScreen-Section1-txt2">
                {t("silver")} 999 &nbsp;
                <span className="app-HomeScreen-Section1-txt4">GM</span>
              </div>
              <div
                className="app-HomeScreen-Section1-txt1"
                style={{
                  color: getTextColor("SILVER 999"),
                }}
              >
                {calculateLiveRate(props?.type, "SILVER 999")}
                {getTextColor("SILVER 999") === "#D2042D" ? (
                  <LuArrowDownRight />
                ) : getTextColor("SILVER 999") === "#50C878" ? (
                  <LuArrowUpRight />
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Section1;
