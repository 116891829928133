import { useCallback } from "react";
import { FcMoneyTransfer } from "react-icons/fc";
import { FiShoppingCart } from "react-icons/fi";

import { List } from "antd";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import goldCube from "../../../../assets/images/goldcube.png";
import silverCube from "../../../../assets/images/silvercube.png";

function Section3(props: any) {
  const { t } = useTranslation();
  const liveRate = useSelector((state: any) => state.liveRate.current);

  const findBalance = (metalType: any) => {
    const metalItem: any = props?.data?.find(
      (item: any) => item.metal_type === metalType
    );
    return metalItem?.balance || 0;
  };

  const gold_995 = findBalance('gold_995');
  const gold_9999 = findBalance('gold_9999');
  const silver_999 = findBalance('silver_999');
  const cash = findBalance('cash');

  // Function to get the live buy rate for each metal type
  const getLiveRateValue = useCallback(
    (metalType: any) => {
      const liveRates: any = {
        'gold_995': liveRate?.find((item: any) => item.metal === 'GOLD 995')?.liveSellRate,
        'gold_9999': liveRate?.find((item: any) => item.metal === 'GOLD 9999')?.liveSellRate,
        'silver_999': liveRate?.find((item: any) => item.metal === 'SILVER 999')?.liveSellRate,
      };
      return liveRates[metalType] || 0;
    },
    [liveRate]
  );

  // Calculating total value of each metal based on the current live rates
  const gold_995_value = gold_995 * getLiveRateValue('gold_995');
  const gold_9999_value = gold_9999 * getLiveRateValue('gold_9999');
  const silver_999_value = silver_999 * getLiveRateValue('silver_999');
  const totalAssets = cash + gold_995_value + gold_9999_value + silver_999_value;

  return (
    <div className="app-WalletScreen-Section3">
      <div className="app-WalletScreen-Section1-Box1">
        <div className="app-WalletScreen-Section1-Box2">
          <FiShoppingCart />
        </div>
        <div>Total Asset</div>
        <div style={{ flex: 1 }}></div>
        <div className="app-WalletScreen-Section3-text1">
          {" "}
          {totalAssets.toFixed(2)}&nbsp;
          <span className="WalletScreen-Section3-text2">AED</span>
        </div>
      </div>
      <br />
      <List
        itemLayout="horizontal"
        dataSource={props.data}
        bordered={false}
        loading={props.isLoading}
        renderItem={(item: any, index) => (
          <div className="WalletScreen-LogItems">
            <div className="WalletScreen-LogItems-icon">
              {item?.metal_type === "gold_995" ||
              item?.metal_type === "gold_9999" ? (
                <img src={goldCube} alt="gold_995" />
              ) : item?.metal_type === "silver_999" ? (
                <img src={silverCube} alt="silver_999" />
              ) : (
                <FcMoneyTransfer />
              )}
            </div>
            <div className="WalletScreen-LogItems2">
              <Row
                style={{
                  height: "100%",
                }}
                className="g-0"
              >
                <Col md={8} xs={6}>
                  <div className="WalletScreen-LogItems3">
                    {item?.metal_type === "gold_995" ||
                    item?.metal_type === "gold_9999" ||
                    item?.metal_type === "silver_999" ? (
                      <span className="WalletScreen-LogItemsText1">
                        {`${item?.balance.toFixed(2)} g`}
                      </span>
                    ) : item?.metal_type === "cash" ? (
                      <span className="WalletScreen-LogItemsText1">
                        {`${item?.balance.toFixed(2)} AED`}
                      </span>
                    ) : null}
                    <div
                      className="WalletScreen-LogItemsText2"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item?.metal_type.replace(/_/g, " ")}
                    </div>
                  </div>
                </Col>
                <Col md={3} xs={6}>
                  <div className="WalletScreen-LogItems3">
                    {item?.metal_type === "gold_995" ? (
                      <div>AED {gold_995_value.toFixed(2)}</div>
                    ) : item?.metal_type === "gold_9999" ? (
                      <div>AED {gold_9999_value.toFixed(2)}</div>
                    ) : item?.metal_type === "silver_999" ? (
                      <div>AED {silver_999_value.toFixed(2)}</div>
                    ) : (
                      <div>AED {cash.toFixed(2)}</div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      />
    </div>
  );
}

export default Section3;
