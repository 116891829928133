import {
  Badge,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Image,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Steps,
  Tag,
  Typography,
} from "antd";
import React, { memo, useEffect, useState } from "react";
import {
  FaBox,
  FaCreditCard,
  FaEnvelope,
  FaMapMarkerAlt,
  FaPhone,
  FaUser,
} from "react-icons/fa";
import { useParams } from "react-router-dom";
import API from "../../../../config/api";
import { GET, PUT } from "../../../../utlis/apiCalls";

const { Title, Text } = Typography;

interface StatusChangeModalProps {
  isModalVisible: boolean;
  handleModalCancel: () => void;
  handleStatusChange: () => void;
  currentStatus: string;
  statusList: Array<any>;
  selectedStatus: string;
  setSelectedStatus: (value: string) => void;
  orderDetails: any;
  isLoading2: boolean;
}

interface OrderDetails {
  order_id: string;
  status: string;
  color: string;
  orderStatus: Array<{
    title: string;
    totalStatuses: number;
  }>;
  userDetails: {
    name: string;
    email: string;
    phoneNumber: string;
  };
  addressDetails: {
    fullAddress: string;
    street: string;
    city: string;
    state: string;
    pin_code: string;
    country: string;
  };
  productDetails: {
    name: string;
    totalItems: number;
    weight: string;
    image: string[];
  };
  totalAmount: number;
  shipping: number;
  tax: number;
  paymentType: string;
}

// Memoized Modal Component with proper typing
const StatusChangeModal = memo(
  ({
    isModalVisible,
    handleModalCancel,
    handleStatusChange,
    currentStatus,
    statusList,
    selectedStatus,
    setSelectedStatus,
    orderDetails,
    isLoading2,
  }: StatusChangeModalProps) => (
    <Modal
      title={
        <Space>
          <span>Change Order Status</span>
        </Space>
      }
      open={isModalVisible}
      onCancel={handleModalCancel}
      footer={[
        <Button key="cancel" onClick={handleModalCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleStatusChange}
          disabled={selectedStatus === orderDetails?.status}
          loading={isLoading2}
        >
          Update Status
        </Button>,
      ]}
    >
      <div style={{ marginTop: 20 }}>
        <Text strong>Current Status: </Text>
        <Tag color={orderDetails?.color}>{orderDetails?.status}</Tag>

        <div style={{ marginTop: 20 }}>
          <Text strong>Select New Status:</Text>
          <Select
            value={selectedStatus}
            onChange={(value) => setSelectedStatus(value)}
            style={{ width: "100%", marginTop: 8 }}
            size="large"
            options={statusList}
            dropdownStyle={{ zIndex: 1100 }}
          />
        </div>
      </div>
    </Modal>
  )
);

const OrderDetailsScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);
  const [statusList, setStatusList] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoading2, setIsLoading2] = useState<boolean>(false);
  const [modalState, setModalState] = useState({
    visible: false,
    selectedStatus: "",
  });

  const fetchCategories = async () => {
    try {
      setIsLoading(true);
      const response: any = await GET(API.ORDER_DEATAILS + id);
      if (response?.status) {
        setOrderDetails(response.data);
      }
    } catch (error) {
      notificationApi.error({
        message: "Error",
        description: "Failed to fetch order details",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStatusType = async () => {
    try {
      const response: any = await GET(API.ORDER_TYPES + id);
      if (response?.status) {
        setStatusList(response.data);
      }
    } catch (error) {
      notificationApi.error({
        message: "Error",
        description: "Failed to fetch status types",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await Promise.all([fetchCategories(), fetchStatusType()]);
      } catch (error) {
        notificationApi.error({
          message: "Error",
          description: "Failed to fetch data",
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, notificationApi]);

  const handleModalOpen = () => {
    setModalState({
      visible: true,
      selectedStatus: orderDetails?.status || "",
    });
  };

  const handleModalCancel = () => {
    setModalState((prev) => ({
      ...prev,
      visible: false,
    }));
  };

  const handleStatusChange = async () => {
    try {
      setIsLoading2(true);
      const response: any = await PUT(API.ORDER_STATUS + id, {
        status: modalState.selectedStatus,
      });
      if (response?.status) {
        notificationApi.success({
          message: "Successfully Update Currency Information",
        });
        fetchCategories();
        fetchStatusType();
        setModalState((prev) => ({
          visible: false,
          selectedStatus: "",
        }));
      }
    } catch (error) {
    } finally {
      setIsLoading2(false);
    }
  };

  return (
    <div style={{ maxWidth: 1200, margin: "24px auto", padding: "0 24px" }}>
      {contextHolder}
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Badge.Ribbon text={orderDetails?.status} color={orderDetails?.color}>
            <Card>
              <Row justify="space-between" align="middle">
                <Col>
                  <Title level={2}>Order Details</Title>
                  <Text type="secondary">Order #{orderDetails?.order_id}</Text>
                </Col>
                <Col>
                  <Space>
                    <Button type="primary" onClick={handleModalOpen}>
                      Change Status
                    </Button>
                  </Space>
                </Col>
              </Row>

              <StatusChangeModal
                isModalVisible={modalState.visible}
                handleModalCancel={handleModalCancel}
                handleStatusChange={handleStatusChange}
                currentStatus={orderDetails?.status || ""}
                statusList={statusList}
                selectedStatus={modalState.selectedStatus}
                setSelectedStatus={(value: string) =>
                  setModalState((prev) => ({
                    ...prev,
                    selectedStatus: value,
                  }))
                }
                orderDetails={orderDetails}
                isLoading2={isLoading2}
              />
              <Steps
                current={orderDetails?.orderStatus?.[0]?.totalStatuses}
                style={{ marginTop: 24 }}
                items={orderDetails?.orderStatus?.map((status: any) => ({
                  ...status,
                  title: status.title,
                  status: ["cancelled", "rejected"].includes(status.title)
                    ? "error"
                    : "",
                }))}
              />
            </Card>
          </Badge.Ribbon>

          <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
            <Col xs={24} lg={16}>
              {/* Customer Information */}
              <Card
                title={
                  <Space>
                    <FaUser />
                    <span>Customer Information</span>
                  </Space>
                }
              >
                <Descriptions column={{ xs: 1, sm: 2 }}>
                  <Descriptions.Item
                    label={
                      <Space>
                        <FaUser />
                        <span>Name</span>
                      </Space>
                    }
                  >
                    {orderDetails?.userDetails?.name}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Space>
                        <FaEnvelope />
                        <span>Email</span>
                      </Space>
                    }
                  >
                    {orderDetails?.userDetails?.email}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <Space>
                        <FaPhone />
                        <span>Phone</span>
                      </Space>
                    }
                  >
                    {orderDetails?.userDetails?.phoneNumber}
                  </Descriptions.Item>
                </Descriptions>

                <Divider />

                <Title level={5}>
                  <Space>
                    <FaMapMarkerAlt />
                    <span>Shipping Address</span>
                  </Space>
                </Title>
                <Text>
                  {orderDetails?.addressDetails?.fullAddress}
                  <br />
                  {orderDetails?.addressDetails?.street}
                  {orderDetails?.addressDetails?.city},{" "}
                  {orderDetails?.addressDetails?.state}{" "}
                  {orderDetails?.addressDetails?.pin_code}
                  <br />
                  {orderDetails?.addressDetails?.country}
                </Text>
              </Card>

              {/* Order Item */}
              <Card
                title={
                  <Space>
                    <FaBox />
                    <span>Order Item</span>
                  </Space>
                }
                style={{ marginTop: 24 }}
              >
                <Row align="middle" gutter={16}>
                  <Col>
                    <Image
                      src={orderDetails?.productDetails?.image[0]}
                      alt={orderDetails?.productDetails?.name}
                      width={120}
                    />
                  </Col>
                  <Col flex="auto">
                    <Space direction="vertical">
                      <Text strong>{orderDetails?.productDetails?.name}</Text>
                      <Text type="secondary">
                        Quantity: {orderDetails?.productDetails?.totalItems}
                      </Text>
                      <Text type="secondary">
                        Weight: {orderDetails?.productDetails?.weight}
                      </Text>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xs={24} lg={8}>
              {/* Payment Summary */}
              <Card
                title={
                  <Space>
                    <FaCreditCard />
                    <span>Payment Summary</span>
                  </Space>
                }
              >
                <Descriptions column={1} bordered>
                  <Descriptions.Item label="Subtotal">
                    ${orderDetails?.totalAmount?.toFixed(2)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Shipping">
                    ${orderDetails?.shipping?.toFixed(2) || 0}
                  </Descriptions.Item>
                  <Descriptions.Item label="Tax">
                    ${orderDetails?.tax?.toFixed(2) || 0}
                  </Descriptions.Item>
                  <Descriptions.Item label="Total" className="font-bold">
                    ${orderDetails?.totalAmount?.toFixed(2) || 0}
                  </Descriptions.Item>
                </Descriptions>

                <Divider />

                <Text type="secondary">
                  <Space>
                    <FaCreditCard />
                    <span>Payment Method: {orderDetails?.paymentType}</span>
                  </Space>
                </Text>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default OrderDetailsScreen;
