import { useState } from "react";
import "../styles.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Currency from "../../../../website/homeScreen/components/currency";
import AppTradeForms from "./tradeForm";

function Section3(props: any) {
  const { t } = useTranslation();
  const curreny = useSelector((state: any) => state?.currencyInfo?.current);
  const liveRate = useSelector((state: any) => state.liveRate);
  const User = useSelector((state: any) => state?.auth?.user);

  return (
    <div className="app-HomeScreen-Section3">
      <div className="app-HomeScreen-Section3-txt2">
        <div>{t("trade_now")}</div>
        <div className="app-HomeScreen-Section3-Select">
          <Currency />
        </div>
      </div> 
      <AppTradeForms
        type={props.type}
        User={User}
        SetType={(value: any) => props.setType(value)}
        curreny={curreny}
        liveRate={liveRate}
        refresh={props?.refreshTrade}
      />
    </div>
  );
}

export default Section3;
