import { Button, Input, notification, Pagination, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { IoIosClose } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import API from "../../../config/api";
import useDebounce from "../../../shared/helpers/useDebounce";
import { DELETE, GET } from "../../../utlis/apiCalls";
import PageHeader from "../../routes/pageHeader";
import DataTable from "./components/dataTable";

interface StatusOption {
  value: string;
  label: string;
}

interface PageMetaType {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

interface DateRange {
  fromDate: string;
  toDate: string;
}

interface OrderState {
  page: number;
  take: number;
  search: string;
  status: string;
  date: DateRange;
}

const DEFAULT_PAGE_SIZE = 10;
const DEBOUNCE_DELAY = 1500;

function Orders() {
  const [notificationApi, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const [orderState, setOrderState] = useState<OrderState>({
    page: 1,
    take: DEFAULT_PAGE_SIZE,
    search: "",
    status: "",
    date: { fromDate: "", toDate: "" },
  });

  const [meta, setMeta] = useState<PageMetaType>();
  const [loading, setLoading] = useState({
    table: false,
    delete: false,
    status: false,
  });
  const [products, setProducts] = useState<any[]>([]);
  const [statusList, setStatusList] = useState<StatusOption[]>([]);

  const debouncedSearch = useDebounce(orderState.search, DEBOUNCE_DELAY);

  const fetchOrders = useCallback(async () => {
    try {
      setLoading((prev) => ({ ...prev, table: true }));
      const { page, take, search, status, date } = orderState;

      const queryParams = new URLSearchParams({
        order: "DESC",
        page: page.toString(),
        take: take.toString(),
        search: search,
        ...(status && { status }),
        ...(date.fromDate && { fromDate: date.fromDate }),
        ...(date.toDate && { toDate: date.toDate }),
      });

      const response: any = await GET(`${API.ORDER_ALL}?${queryParams}`);

      if (response?.status) {
        setProducts(response.data.data);
        setMeta(response.data.meta);
      } else {
        setProducts([]);
        setMeta(undefined);
      }
    } catch (error) {
      notificationApi.error({
        message: "Error",
        description: "Failed to fetch orders",
      });
    } finally {
      setLoading((prev) => ({ ...prev, table: false }));
    }
  }, [orderState, notificationApi]);

  const fetchOrderTypes = async () => {
    try {
      setLoading((prev) => ({ ...prev, status: true }));
      const response: any = await GET(API.ORDER_STATUS_LIST);

      if (response?.status) {
        setStatusList([{ value: "", label: "All" }, ...response.data]);
      }
    } catch (error) {
      notificationApi.error({
        message: "Error",
        description: "Failed to fetch status types",
      });
    } finally {
      setLoading((prev) => ({ ...prev, status: false }));
    }
  };

  // Event Handlers
  const handleStateChange = (key: keyof OrderState, value: any) => {
    setOrderState((prev) => ({
      ...prev,
      [key]: value,
      ...(key !== "page" && { page: 1 }), // Reset page on filter changes
    }));
  };

  const handleRefresh = () => {
    setOrderState({
      page: 1,
      take: DEFAULT_PAGE_SIZE,
      search: "",
      status: "",
      date: { fromDate: "", toDate: "" },
    });
  };

  const deleteCategory = async (id: number) => {
    try {
      setLoading((prev) => ({ ...prev, delete: true }));
      const response: any = await DELETE(`${API.CATEGORY}/${id}`);

      if (response?.status) {
        fetchOrders();
        notificationApi.success({
          message: "Success",
          description: response.message || "Category deleted successfully",
        });
      }
    } catch (error: any) {
      notificationApi.error({
        message: "Error",
        description: error?.message || "Failed to delete category",
      });
    } finally {
      setLoading((prev) => ({ ...prev, delete: false }));
    }
  };

  // Effects
  useEffect(() => {
    fetchOrders();
  }, [fetchOrders, debouncedSearch]);

  useEffect(() => {
    fetchOrderTypes();
  }, []);

  // Render
  return (
    <div>
      {contextHolder}
      <br />
      <Container fluid={false}>
        <PageHeader title="Orders">
          <div className="d-flex align-items-center gap-4">
            <Select
              className="min-w-[200px]"
              value={orderState.status}
              onChange={(value) => handleStateChange("status", value)}
              options={statusList}
              placeholder="Select Status"
              loading={loading.status}
              allowClear
            />

            <Input
              className="min-w-[200px]"
              value={orderState.search}
              onChange={(e) => handleStateChange("search", e.target.value)}
              prefix={<IoSearchOutline size={20} className="text-gray-400" />}
              placeholder="Search here.."
              suffix={
                orderState.search && (
                  <IoIosClose
                    size={20}
                    className="text-gray-400 cursor-pointer"
                    onClick={() => handleStateChange("search", "")}
                  />
                )
              }
            />

            <Button type="primary" onClick={handleRefresh}>
              Refresh
            </Button>

            <Button
              type="primary"
              onClick={() => navigate("/admin/category/create")}
            >
              Create
            </Button>
          </div>
        </PageHeader>

        <div className="overflow-x-auto">
          <DataTable
            isLoading={loading.table}
            allTrade={products}
            isLoading2={loading.delete}
            deleteCategory={deleteCategory}
          />

          {meta && (
            <Pagination
              className="mt-4 text-center"
              current={orderState.page}
              total={meta.itemCount}
              pageSize={orderState.take}
              showSizeChanger
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              pageSizeOptions={[3, 10, 20, 50]}
              onChange={(page, pageSize) => {
                window.scrollTo(0, 0);
                handleStateChange("page", page);
                handleStateChange("take", pageSize);
              }}
            />
          )}
        </div>
      </Container>
    </div>
  );
}

export default Orders;
