import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Form,
  Input,
  message,
  notification,
  Select,
  Spin,
  Upload,
} from "antd";
import type { RcFile, UploadFile } from "antd/es/upload/interface";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../../../config/api";
import { COMPRESS_IMAGE, GET, POST, PUT } from "../../../../utlis/apiCalls";
import PageHeader from "../../../routes/pageHeader";

interface ProductFormValues {
  name: string;
  description: string;
  image: string[];
  weight: string;
  purity: string;
  tags: string;
  category: number;
  sku: string;
}

interface Category {
  id: number;
  name: string;
}

const { TextArea } = Input;

const ProductForm: React.FC = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState({
    submit: false,
    image: false,
    category: false,
    page: false,
  });
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);

  const isEditMode = id !== "create";

  useEffect(() => {
    fetchCategories();
    if (isEditMode) {
      fetchProduct();
    }
  }, [isEditMode]);

  const fetchCategories = async (searchValue?: string) => {
    try {
      setLoading((prev) => ({ ...prev, category: true }));
      const queryParams = new URLSearchParams({
        order: "DESC",
        page: "1",
        take: "100",
        ...(searchValue && { search: searchValue.trim() }),
      }).toString();

      const response: any = await GET(`${API.CATEGORY_ALL}?${queryParams}`);
      if (response?.status) {
        setCategories(response.data.data);
      } else {
        setCategories([]);
      }
    } catch (error) {
      notificationApi.error({
        message: "Error",
        description: "Failed to fetch categories",
      });
    } finally {
      setLoading((prev) => ({ ...prev, category: false }));
    }
  };

  const fetchProduct = async () => {
    try {
      setLoading((prev) => ({ ...prev, page: true }));
      const response: any = await GET(`${API.PRODUCTS}/${id}`);

      if (response?.status) {
        const productData = response.data;

        // Set form values
        form.setFieldsValue({
          ...productData,
        });

        if (productData.image) {
          const images = Array.isArray(productData.image)
            ? productData.image
            : [productData.image];

          const imageList = images.map((img: string, index: number) => ({
            uid: `-${index}`,
            name: `image-${index + 1}`,
            status: "done",
            url: img,
          }));

          setFileList(imageList);
        }
      }
    } catch (error) {
      notificationApi.error({
        message: "Error",
        description: "Failed to fetch product details",
      });
    } finally {
      setLoading((prev) => ({ ...prev, page: false }));
    }
  };

  const handleUpload = async (file: RcFile): Promise<string> => {
    try {
      setLoading((prev) => ({ ...prev, image: true }));
      const fileName = `${moment().unix()}_${file.name}`;
      const imageBlob = await fetch(URL.createObjectURL(file)).then((r) =>
        r.blob()
      );
      const processedFile = new File([imageBlob], fileName, {
        type: imageBlob.type,
      });

      const response: any = await COMPRESS_IMAGE(processedFile);
      if (!response?.status) {
        throw new Error(response?.message || "Image upload failed");
      }

      return response.url;
    } catch (error) {
      throw new Error("Failed to upload image");
    } finally {
      setLoading((prev) => ({ ...prev, image: false }));
    }
  };

  const handleSubmit = async (values: ProductFormValues) => {
    try {
      setLoading((prev) => ({ ...prev, submit: true }));

      // Validate images
      if (fileList.length === 0) {
        message.error("Please upload at least one image");
        return;
      }

      // Process images
      const imagePromises = fileList.map(async (file) => {
        if (file.originFileObj) {
          return handleUpload(file?.originFileObj);
        }
        return file.url;
      });

      const imageUrls = await Promise.all(imagePromises);
      const validUrls = imageUrls.filter((url): url is string => Boolean(url));

      if (validUrls.length === 0) {
        throw new Error("No images were successfully processed");
      }

      const finalValues = {
        ...values,
        image: validUrls,
      };

      // Submit data
      const method = isEditMode ? PUT : POST;
      const url = isEditMode ? `${API.PRODUCTS}/${id}` : API.PRODUCTS_CREATE;
      const response: any = await method(url, finalValues);

      if (!response.status) {
        throw new Error(response.message || "Failed to save product");
      }

      message.success(
        `Product ${isEditMode ? "updated" : "created"} successfully!`
      );
      navigate("/admin/products");
    } catch (error: any) {
      notificationApi.error({
        message: "Error",
        description:
          error.message ||
          `Failed to ${isEditMode ? "update" : "create"} product`,
      });
    } finally {
      setLoading((prev) => ({ ...prev, submit: false }));
    }
  };

  if (loading.page) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Container fluid={false}>
      {contextHolder}
      <PageHeader title={isEditMode ? "Edit Product" : "Create Product"} />
      <Card>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Form.Item
            name="name"
            label="Product Name"
            rules={[
              { required: true, message: "Please enter product name" },
              { min: 3, message: "Name must be at least 3 characters" },
            ]}
          >
            <Input placeholder="Enter product name" size="large" />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Please enter product description" },
              {
                min: 10,
                message: "Description must be at least 10 characters",
              },
            ]}
          >
            <TextArea rows={4} placeholder="Enter product description" />
          </Form.Item>

          <Form.Item
            name="image"
            label="Product Images"
            rules={[
              { required: true, message: "Please upload at least one image" },
            ]}
          >
            <Upload
              listType="picture-card"
              fileList={fileList}
              beforeUpload={(file) => {
                const isJpgOrPng =
                  file.type === "image/jpeg" || file.type === "image/png";
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJpgOrPng)
                  message.error("You can only upload JPG/PNG files!");
                if (!isLt2M) message.error("Image must be smaller than 2MB!");

                return isJpgOrPng && isLt2M;
              }}
              onChange={({ fileList }) => setFileList(fileList)}
              multiple
              maxCount={5}
              customRequest={({ onSuccess }) => onSuccess?.("ok")}
              onPreview={(file) => window.open(file.url)}
            >
              {fileList.length >= 5 ? null : (
                <div>
                  {loading.image ? <LoadingOutlined /> : <PlusOutlined />}
                  <div className="mt-2">Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Weight and Purity */}
            <Form.Item
              name="weight"
              label="Weight (g)"
              rules={[
                { required: true, message: "Please enter weight" },
                {
                  pattern: /^\d*\.?\d+$/,
                  message: "Please enter a valid number",
                },
              ]}
            >
              <Input placeholder="Enter weight in grams" size="large" />
            </Form.Item>

            <Form.Item
              name="purity"
              label="Purity"
              rules={[
                { required: true, message: "Please enter purity" },
                { pattern: /^\d+$/, message: "Please enter a valid number" },
              ]}
            >
              <Input placeholder="Enter purity (e.g., 9999)" size="large" />
            </Form.Item>

            {/* Category */}
            <Form.Item
              name="category"
              label="Category"
              rules={[{ required: true, message: "Please select category" }]}
            >
              <Select
                showSearch
                placeholder="Select category"
                size="large"
                loading={loading.category}
                filterOption={false}
                onSearch={fetchCategories}
                allowClear
                options={categories.map((cat) => ({
                  value: cat.id,
                  label: cat.name,
                }))}
              />
            </Form.Item>
          </div>

          {/* SKU and Tags */}
          <Form.Item
            name="sku"
            label="SKU"
            rules={[
              { required: true, message: "Please enter SKU" },
              { len: 10, message: "SKU must be exactly 10 characters" },
            ]}
            extra="SKU must be exactly 10 characters"
          >
            <Input
              placeholder="Enter SKU (exactly 10 characters)"
              size="large"
              maxLength={10}
              showCount
            />
          </Form.Item>

          <Form.Item
            name="tags"
            label="Tags"
            rules={[{ required: true, message: "Please enter tags" }]}
          >
            <Input placeholder="Enter tags (comma separated)" size="large" />
          </Form.Item>

          {/* Submit Buttons */}
          <Form.Item>
            <div className="flex justify-end gap-4">
              <Button
                onClick={() => {
                  form.resetFields();
                  setFileList([]);
                }}
                size="large"
                disabled={loading.submit}
              >
                Reset
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading.submit}
                size="large"
              >
                {isEditMode ? "Update" : "Create"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </Container>
  );
};

export default ProductForm;
