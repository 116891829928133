import { useEffect, useState } from "react";
import "./styles.scss";
import { useSelector } from "react-redux";
import { DatePicker, Input, message } from "antd";
import { Container } from "react-bootstrap";

import moment from "moment";
import useDebounce from "../../../shared/helpers/useDebounce";

import { useTranslation } from "react-i18next";
import { IoIosClose } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";

import AppPageHeader from "../../routes/appPageHeader";
import DataList from "./components/dataList";

import API from "../../../config/api";
import { GET } from "../../../utlis/apiCalls";

export default function TransactionLogScreen() {
  const { t } = useTranslation();
  const User = useSelector((state: any) => state.auth.user);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const [searchValue, setSearchValue] = useState<string>("");
  const [searchDate, setDate] = useState({ fromDate: "", toDate: "" });

  const [data, setData] = useState<any>();
  const [meta, setMeta] = useState<any>();

  useEffect(() => {
    getTransaction();
  }, [page, useDebounce(searchValue, 1500), searchDate, pageSize]);

  const getTransaction = async () => {
    try {
      setIsLoading(true);
      let url = API.TRANSACTION_LOG_ALL + User?.id;
      let query = `?order=DESC&page=${page}&take=${pageSize}`;
      let searchFilter = `&search=${searchValue}`;
      let dateFilter = `&fromDate=${searchDate.fromDate}&toDate=${searchDate.toDate}`;
      const response: any = await GET(
        url + query + searchFilter + dateFilter,
        null
      );
      if (response?.status) {
        setData(response.data.data);
        setMeta(response?.data?.meta);
      }
    } catch (error) {
      message.error("oops. something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setPage(1);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePageSize = (current: number, page: number) => {
    setPage(current);
    setPageSize(page);
  };

  const DateChange = (dates: any, dateStrings: any) => {
    if (dateStrings[0] && dateStrings[1]) {
      setDate({
        fromDate: moment(dateStrings[0]).format("YYYY-MM-DD"),
        toDate: moment(dateStrings[1]).format("YYYY-MM-DD"),
      });
    } else {
      setDate({
        fromDate: " ",
        toDate: "",
      });
    }
  };

  return (
    <div>
      <AppPageHeader title="transactions"></AppPageHeader>
      <Container>
        <div className="transactionLogScreen-Fileters">
          {/* <DatePicker.RangePicker
            onChange={DateChange}
            className="transactionLogScreen-Fileters-RangePicker"
          /> */}
          <Input
            className="transactionLogScreen-Fileters-search"
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
            prefix={<IoSearchOutline size={20} color="#ffffffee" />}
            placeholder={`${t("search_here")}...`}
            suffix={
              <IoIosClose
                size={20}
                color="#ffffffee"
                cursor={"pointer"}
                onClick={() => setSearchValue("")}
              />
            }
          />
        </div>
        <div className="transactionLogScreen-Fileters-Txt1">
          {t("transaction_history")}
        </div>
        <DataList
          isLoading={isLoading}
          data={data}
          meta={meta}
          page={page}
          pageSize={pageSize}
          handlePageSize={handlePageSize}
          handlePageChange={handlePageChange}
        />
        <br />
      </Container>
    </div>
  );
}
