import {
  Button,
  Card,
  Form,
  InputNumber,
  Popconfirm,
  Tag,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import API from "../../../config/api";
import { GET, PUT } from "../../../utlis/apiCalls";
import PageHeader from "../../routes/pageHeader";
import "./styles.scss";
import { CheckOutlined, LoadingOutlined } from "@ant-design/icons";

function Script(props: any) {
  const [form] = useForm();
  const liveRate: any = useSelector((state: any) => state.liveRate);
  const [scripts, setScripts] = useState<any>();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>(
    {}
  );
  const [ounceLoadingStates, setOunceLoadingStates] = useState<
    Record<string, boolean>
  >({});
  const [successStates, setSuccessStates] = useState<Record<string, boolean>>(
    {}
  );
  const [ounceSuccessStates, setOunceSuccessStates] = useState<
    Record<string, boolean>
  >({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchAllScripts();
  }, []);

  const fetchAllScripts = async () => {
    try {
      setIsLoading(true);
      const response: any = await GET(API.SCRIPT_ALL, null);
      if (response?.status) {
        setScripts(response.data);
      } else {
        notificationApi.error({ message: response.message });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async (
    itemId: number,
    mode: string,
    isOunceTable: boolean = false
  ) => {
    try {
      if (isOunceTable) {
        setOunceLoadingStates((prev: Record<string, boolean>) => ({
          ...prev,
          [itemId]: true,
        }));
      } else {
        setLoadingStates((prev: Record<string, boolean>) => ({
          ...prev,
          [itemId]: true,
        }));
      }

      const formValues = form.getFieldsValue();

      let body;
      if (mode === "reset") {
        if (isOunceTable) {
          console.log('isOunceTable')
          body = {
            oz_ask_variant: 0,
            oz_bid_variant: 0,
            discount: 0,
            premium: 0,
            buy_variant: formValues[`buy_${itemId}`] || 0,
            sell_variant: formValues[`sell_${itemId}`] || 0,
          };
          form.setFieldsValue({
            [`oz_ask_${itemId}`]: 0,
            [`oz_bid_${itemId}`]: 0,
            [`discount_${itemId}`]: 0,
            [`premium_${itemId}`]: 0,
          });
        } else {
          body = {
            buy_variant: 0,
            sell_variant: 0,
            oz_variant: formValues[`oz_${itemId}`] || 0,
            discount: formValues[`discount_${itemId}`] || 0,
            premium: formValues[`premium_${itemId}`] || 0,
          };
          form.setFieldsValue({
            [`buy_${itemId}`]: 0,
            [`sell_${itemId}`]: 0,
          });
        }
      } else {
        body = {
          buy_variant: formValues[`buy_${itemId}`] || 0,
          sell_variant: formValues[`sell_${itemId}`] || 0,
          discount: formValues[`discount_${itemId}`] || 0,
          premium: formValues[`premium_${itemId}`] || 0,
          oz_ask_variant: formValues[`oz_ask_${itemId}`] || 0,
          oz_bid_variant: formValues[`oz_bid_${itemId}`] || 0,
        };
      }

      const response: any = await PUT(`${API.SCRIPT_UPDATE}${itemId}`, body);
      if (response?.status) {
        if (isOunceTable) {
          setOunceSuccessStates((prev: Record<string, boolean>) => ({
            ...prev,
            [itemId]: true,
          }));
          setTimeout(() => {
            setOunceSuccessStates((prev: Record<string, boolean>) => ({
              ...prev,
              [itemId]: false,
            }));
          }, 1000);
        } else {
          setSuccessStates((prev: Record<string, boolean>) => ({
            ...prev,
            [itemId]: true,
          }));
          setTimeout(() => {
            setSuccessStates((prev: Record<string, boolean>) => ({
              ...prev,
              [itemId]: false,
            }));
          }, 1000);
        }
        fetchAllScripts();
      } else {
        notificationApi.error({ message: response.message });
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (isOunceTable) {
        setOunceLoadingStates((prev: Record<string, boolean>) => ({
          ...prev,
          [itemId]: false,
        }));
      } else {
        setLoadingStates((prev: Record<string, boolean>) => ({
          ...prev,
          [itemId]: false,
        }));
      }
    }
  };

  return (
    <div>
      {contextHolder}
      <br />
      <Container fluid={false}>
        <PageHeader title={"Script"}>
          <div style={{ display: "flex", gap: "5px" }}>
            <Button
              type="primary"
              loading={isLoading}
              onClick={fetchAllScripts}
              style={{ width: "100px" }}
            >
              Refresh
            </Button>
          </div>
        </PageHeader>
        <Form form={form}>
          <Card className="admin-ScriptScreen-table-card">
            <Table bordered hover>
              <thead>
                <tr>
                  <th rowSpan={2} className="admin-script-center-cell">
                    Positon
                  </th>
                  <th rowSpan={2} className="admin-script-center-cell">
                    Metal
                  </th>
                  <th colSpan={3} className="admin-script-center-cell">
                    Buy
                  </th>
                  <th colSpan={3} className="admin-script-center-cell">
                    Sell
                  </th>

                  <th
                    rowSpan={2}
                    colSpan={2}
                    className="admin-script-center-cell"
                  >
                    Actions
                  </th>
                </tr>
                <tr>
                  <th className="admin-script-center-cell">Live Rate</th>
                  <th className="admin-script-center-cell">Variant</th>
                  <th className="admin-script-center-cell">Total Live Rate</th>
                  <th className="admin-script-center-cell">Live Rate</th>
                  <th className="admin-script-center-cell">Variant</th>
                  <th className="admin-script-center-cell">Total Live Rate</th>
                </tr>
              </thead>
              <tbody>
                {scripts?.map((item: any, index: any) => (
                  <tr key={item.id}>
                    <td className="admin-script-center-cell">
                      {item.position}
                    </td>
                    <td className="admin-script-center-cell">
                      <Tag>{item.script_name}</Tag>
                    </td>
                    <td className="admin-script-center-cell">
                      {liveRate?.current
                        ?.filter((i: any) => i?.metal === item?.script_name)[0]
                        ?.liveBuyRate?.toFixed(2)}
                    </td>
                    <td className="admin-script-center-cell">
                      {" "}
                      <Form.Item
                        name={`buy_${item.id}`}
                        initialValue={item?.buy_variant || 0}
                      >
                        <InputNumber
                          className="color-dark"
                          style={{ width: "200px" }}
                          size="middle"
                          controls
                          value={item?.buy_variant}
                        />
                      </Form.Item>
                    </td>
                    <td className="admin-script-center-cell">
                      {(
                        Number(form.getFieldValue(`buy_${item.id}`)) +
                        Number(
                          liveRate?.current?.filter(
                            (i: any) => i?.metal === item?.script_name
                          )[0]?.liveBuyRate
                        )
                      ).toFixed(2)}
                    </td>
                    <td className="admin-script-center-cell">
                      {liveRate?.current
                        ?.filter((i: any) => i?.metal === item?.script_name)[0]
                        ?.liveSellRate?.toFixed(2)}
                    </td>
                    <td className="admin-script-center-cell">
                      <Form.Item
                        name={`sell_${item.id}`}
                        initialValue={item?.sell_variant || 0}
                      >
                        <InputNumber
                          className="color-dark"
                          style={{ width: "200px" }}
                          controls
                          value={item?.sell_variant}
                          size="middle"
                        />
                      </Form.Item>
                    </td>
                    <td className="admin-script-center-cell">
                      {(
                        Number(
                          liveRate?.current
                            ?.filter(
                              (i: any) => i?.metal === item?.script_name
                            )[0]
                            ?.liveSellRate?.toFixed(2)
                        ) + Number(form.getFieldValue(`sell_${item.id}`))
                      ).toFixed(2)}
                    </td>
                    <td>
                      <Button
                        // loading={loadingStates[item.id]}
                        onClick={() => handleSave(item?.id, "save")}
                        style={{ width: "100px" }}
                        icon={successStates[item.id] ? <CheckOutlined /> : null}
                      >
                        {loadingStates[item.id] ? (
                          <LoadingOutlined />
                        ) : successStates[item.id] ? (
                          "Saved"
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </td>
                    <td>
                      <Popconfirm
                        title="Reset Buy/Sell Variants"
                        description="Are you sure to reset buy/sell variants?"
                        onConfirm={() => handleSave(item.id, "reset", false)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button style={{ width: "100px" }}>Reset</Button>
                      </Popconfirm>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Form>
        <Form form={form} className="mt-5 mb-5">
          <Card className="admin-ScriptScreen-table-card">
            <Table bordered hover>
              <thead>
                <tr>
                  <th rowSpan={2} className="admin-script-center-cell">
                    Position
                  </th>
                  <th rowSpan={2} className="admin-script-center-cell">
                    Metal
                  </th>
                  <th colSpan={3} className="admin-script-center-cell">
                    Oz Bid Rate (USD)
                  </th>
                  <th
                    rowSpan={2}
                    style={{ borderRightWidth: 2, borderRightColor: "lightgrey" }}
                    className="admin-script-center-cell"
                  >
                    Oz Bid (D/P) $
                  </th>
                  <th colSpan={3} className="admin-script-center-cell">
                    Oz Ask Rate (USD)
                  </th>
                  <th rowSpan={2} className="admin-script-center-cell">
                    Oz Ask (D/P) $
                  </th>
                  <th
                    colSpan={2}
                    rowSpan={2}
                    className="admin-script-center-cell"
                  >
                    Actions
                  </th>
                </tr>
                <tr>
                  <th className="admin-script-center-cell">Live Oz Bid Rate</th>
                  <th className="admin-script-center-cell">Variant</th>
                  <th className="admin-script-center-cell">
                    Total Oz Bid Rate
                  </th>
                  <th className="admin-script-center-cell">Live Oz Ask Rate</th>
                  <th className="admin-script-center-cell">Variant</th>
                  <th className="admin-script-center-cell">
                    Total Oz Ask Rate
                  </th>
                </tr>
              </thead>
              <tbody>
                {scripts
                  ?.filter((item: any) =>
                    ["GOLD 9999", "SILVER 999"].includes(item.script_name)
                  )
                  ?.map((item: any, index: any) => (
                    <tr key={item.id}>
                      <td className="admin-script-center-cell">{index + 1}</td>
                      <td className="admin-script-center-cell">
                        <Tag>
                          {item.script_name === "GOLD 9999"
                            ? "XAU/USD"
                            : "XAG/USD"}
                        </Tag>
                      </td>
                      <td style={{minWidth: 100}} className="admin-script-center-cell">
                        {liveRate?.current
                          ?.filter(
                            (i: any) => i?.metal === item?.script_name
                          )[0]
                          ?.liveOunceUsdBid?.toFixed(2)}
                      </td>
                      <td className="admin-script-center-cell">
                        {" "}
                        <Form.Item
                          name={`oz_bid_${item.id}`}
                          initialValue={item?.oz_bid_variant || 0}
                        >
                          <InputNumber
                            className="color-dark"
                            style={{ width: "200px" }}
                            size="middle"
                            controls
                            value={item?.oz_bid_variant}
                          />
                        </Form.Item>
                      </td>
                      <td style={{minWidth: 100}} className="admin-script-center-cell">
                        {(
                          Number(
                            liveRate?.current?.filter(
                              (i: any) => i?.metal === item?.script_name
                            )[0]?.liveOunceUsdBid
                          ) +
                          Number(form.getFieldValue(`oz_bid_${item.id}`)) +
                          Number(form.getFieldValue(`discount_${item.id}`))
                        ).toFixed(2)}
                      </td>
                      {/* BIS OZ D/P */}
                      <td
                        className="admin-script-center-cell"
                        style={{
                          borderRightWidth: 2,
                          borderRightColor: "lightgrey",
                        }}
                      >
                        <Form.Item
                          name={`discount_${item.id}`}
                          initialValue={item?.discount || 0}
                        >
                          <InputNumber
                            type="number"
                            className="color-dark"
                            size="middle"
                            value={item?.discount}
                          />
                        </Form.Item>
                      </td>

                      {/* Oz Bid Rate */}
                      <td style={{minWidth: 100}} className="admin-script-center-cell">
                        {liveRate?.current
                          ?.filter(
                            (i: any) => i?.metal === item?.script_name
                          )[0]
                          ?.liveOunceUsdAsk?.toFixed(2)}
                      </td>
                      <td className="admin-script-center-cell">
                        {" "}
                        <Form.Item
                          name={`oz_ask_${item.id}`}
                          initialValue={item?.oz_ask_variant || 0}
                        >
                          <InputNumber
                            className="color-dark"
                            style={{ width: "200px" }}
                            size="middle"
                            controls
                            value={item?.oz_ask_variant}
                          />
                        </Form.Item>
                      </td>
                      <td style={{minWidth: 100}} className="admin-script-center-cell">
                        {(
                          Number(
                            liveRate?.current?.filter(
                              (i: any) => i?.metal === item?.script_name
                            )[0]?.liveOunceUsdAsk
                          ) +
                          Number(form.getFieldValue(`oz_ask_${item.id}`)) +
                          Number(form.getFieldValue(`premium_${item.id}`))
                        ).toFixed(2)}
                      </td>

                      {/* ASK OZ D/P */}
                      <td className="admin-script-center-cell">
                        <Form.Item
                          name={`premium_${item.id}`}
                          initialValue={item?.premium || 0}
                        >
                          <InputNumber
                            className="color-dark"
                            size="middle"
                            value={item?.premium}
                          />
                        </Form.Item>
                      </td>
                      <td>
                        <Button
                          onClick={() => handleSave(item?.id, "save", true)}
                          style={{ width: "100px" }}
                          icon={
                            ounceSuccessStates[item.id] ? (
                              <CheckOutlined />
                            ) : null
                          }
                        >
                          {ounceLoadingStates[item.id] ? (
                            <LoadingOutlined />
                          ) : ounceSuccessStates[item.id] ? (
                            "Saved"
                          ) : (
                            "Save"
                          )}
                        </Button>
                      </td>
                      <td>
                        <Popconfirm
                          title="Reset Ounce Values"
                          description="Are you sure to reset ounce-related values?"
                          onConfirm={() => handleSave(item?.id, "reset", true)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button style={{ width: "100px" }}>Reset</Button>
                        </Popconfirm>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Card>
        </Form>
      </Container>
    </div>
  );
}

export default Script;
