import { Card, Popconfirm, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import { AiOutlineEdit, AiTwotoneDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

interface TradeItem {
  id: string;
  name: string;
  weight: number;
  purity: number;
  quantity: number;
  sku: string;
  createdAt: string;
}

interface DataTableProps {
  allTrade?: TradeItem[];
  isLoading?: boolean;
  isLoading2?: boolean;
  deleteCategory?: (id: number) => void;
}

const DataTable: React.FC<DataTableProps> = ({
  allTrade = [],
  isLoading = false,
  isLoading2 = false,
  deleteCategory,
}) => {
  const navigate = useNavigate();

  const handleDelete = (id: string) => {
    if (deleteCategory) {
      deleteCategory(Number(id));
    }
  };

  const columns: ColumnsType<TradeItem> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      render: (value: number) => value,
    },
    {
      title: "Purity",
      dataIndex: "purity",
      key: "purity",
      render: (value: number) => `${value}`,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (value: number) => value,
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => (
        <span>{moment(date).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: 100,
      render: (id: string, record) => (
        <div className="flex items-center gap-3">
          <AiOutlineEdit
            size={25}
            className="cursor-pointer text-blue-500 hover:text-blue-600"
            onClick={() => navigate(`/admin/products/${id}`)}
          />
          <Popconfirm
            title="Delete Category"
            description={`Are you sure you want to delete "${record.name}"?`}
            onConfirm={() => handleDelete(id)}
            okText="Yes, Delete"
            cancelText="No, Cancel"
            okButtonProps={{
              danger: true,
              size: "middle",
              loading: isLoading2,
            }}
            cancelButtonProps={{
              size: "middle",
            }}
            placement="leftTop"
          >
            <span className="inline-block">
              <AiTwotoneDelete
                size={25}
                className="cursor-pointer text-red-500 hover:text-red-600"
              />
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Card className="commen-table-Card">
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={allTrade}
        pagination={false}
        className="commen-table-custom-scrollbar"
        size="small"
        rowKey="id"
      />
    </Card>
  );
};

export default DataTable;
