import { useTranslation } from "react-i18next";
import AppTradeList from "./appTradeList";

function Section4(props: any) {
  const { t } = useTranslation();
  return (
    <div className="app-HomeScreen-Section4">
      <div className="app-HomeScreen-Section4-txt2">
        {t("trade_history")} &nbsp;
        <span className="app-HomeScreen-Section4-txt1">/ {t("buy_sale")}</span>
      </div>
      <br />
      <AppTradeList data={props?.data} />
      <br />
    </div>
  );
}

export default Section4;
