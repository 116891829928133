import { Button, Card, Form, Modal, notification } from "antd";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import PageHeader from "../../routes/pageHeader";
import { LuRefreshCcw } from "react-icons/lu";
import { GET, PUT } from "../../../utlis/apiCalls";
import API from "../../../config/api";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import "./styles.scss";
export default function RedeemDetails() {
  const param = useParams();
  const [form] = Form.useForm();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [redeemDetails, setRedeemDetails] = useState<any>();
  const [isLoading, setIsLoading] = useState<any>(false);
  const [isLoading2, setIsLoading2] = useState<any>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState<string>();

  useEffect(() => {
    getRedeemDetails();
  }, [refresh]);
  const getRedeemDetails = async () => {
    try {
      setIsLoading(true);
      const response: any = await GET(API.REDEEM + param.id, null);
      if (response.status) {
        setRedeemDetails(response?.data);
      } else {
        notificationApi.error({ message: response.message });
      }
    } catch (error) {
      //console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const statusChange = async () => {
    try {
      setIsLoading2(true);
      const obj = {
        id: Number(param?.id),
        status,
      };
      const response: any = await PUT(API.REDEEM_UPDATE, obj);
      if (response?.status) {
        notificationApi.success({
          message: `successfully changed status to ${status}`,
        });
        setRefresh(!refresh);
        setIsModalOpen(false);
      } else {
        notificationApi.error({
          message: response?.message,
        });
      }
    } catch (error) {
    } finally {
      setIsLoading2(false);
    }
  };
  return (
    <div>
      {contextHolder}
      <br />
      <Container fluid={false}>
        <PageHeader title={"Redeem Details"}>
          <div className="d-flex">
            <div>
              <Button
                disabled={redeemDetails?.status === "pending" ? false : true}
                type="primary"
                color="green"
                onClick={() => {
                  setStatus("accepted");
                  setIsModalOpen(true);
                }}
              >
                Accept
              </Button>
            </div>
            <div style={{ width: 20 }} />
            <div>
              <Button
                disabled={redeemDetails?.status === "pending" ? false : true}
                type="primary"
                danger
                onClick={() => {
                  setStatus("rejected");
                  setIsModalOpen(true);
                }}
              >
                Reject
              </Button>
            </div>
            <div style={{ width: 20 }} />
            <div>
              <Button
                type="primary"
                onClick={() => {
                  setRefresh(!refresh);
                }}
              >
                <LuRefreshCcw size={20} />
              </Button>
            </div>
          </div>
        </PageHeader>
        <div className="overFlow-Box1">
          <Card loading={isLoading}>
            <Row>
              <Col sm={6}>
                {" "}
                <div style={{ marginLeft: 50 }}>
                  <div className="mb-3">
                    <span className="redeemDetails-text1">Name :</span>
                    <span className="redeemDetails-text2">
                      {redeemDetails?.user?.name}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="redeemDetails-text1">Phone Number:</span>{" "}
                    <span className="redeemDetails-text2">
                      {redeemDetails?.phone_number}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="redeemDetails-text1">Address :</span>{" "}
                    <span className="redeemDetails-text2">
                      {redeemDetails?.address}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="redeemDetails-text1">Type :</span>{" "}
                    <span className="redeemDetails-text2">
                      {redeemDetails?.type}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="redeemDetails-text1">Status :</span>{" "}
                    <span className="redeemDetails-text2">
                      {redeemDetails?.status}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="redeemDetails-text1">Gold 995:</span>{" "}
                    <span className="redeemDetails-text2">
                      {redeemDetails?.metals_amount?.gold_995}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="redeemDetails-text1">Gold 9999:</span>{" "}
                    <span className="redeemDetails-text2">
                      {redeemDetails?.metals_amount?.gold_9999}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="redeemDetails-text1">Silver 999:</span>{" "}
                    <span className="redeemDetails-text2">
                      {redeemDetails?.metals_amount?.silver_999}
                    </span>
                  </div>
                  <div className="mb-3">
                    <span className="redeemDetails-text1">Created Date</span>:
                    <span className="redeemDetails-text2">
                      {moment(redeemDetails?.createdAt).format("DD/MM/YYYY")}
                    </span>
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div>
                  <span className="redeemDetails-text1">Location</span>
                </div>
                <iframe
                  src={`https://www.google.com/maps/embed/v1/place?key=${API.GGL_TOKEN}&q=${redeemDetails?.lat},${redeemDetails?.long}&center=${redeemDetails?.lat},${redeemDetails?.long}`}
                  width="80%"
                  height="300"
                  className="redeemDetails-map"
                  style={{ border: 0 }}
                  allowFullScreen={true}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </Col>
            </Row>
          </Card>
        </div>
      </Container>
      <Modal
        onOk={statusChange}
        title={`Are You Sure to ${
          status == "accepted" ? "Accept" : "Reject"
        } Redeem`}
        open={isModalOpen}
        okButtonProps={{ loading: isLoading2 }}
        onCancel={() => setIsModalOpen(false)}
      >
        <br />
        <br />
      </Modal>
    </div>
  );
}
