import "./styles.scss";

import { Alert, Button, Form, Input, Radio, Select, message } from "antd";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { useTranslation } from "react-i18next";

import { useForm } from "antd/es/form/Form";
import { useSelector } from "react-redux";
import { KycRequestBody, statusType } from "../../../shared/types/kyc";

import ImagePicker from "../../../components/imagePicker";
import API from "../../../config/api";
import { GET, POST, PUT } from "../../../utlis/apiCalls";

import { fetchUserCountry } from "../../../shared/helpers/functions";
import AppPageHeader from "../../routes/appPageHeader";
import AutoCompleteLocation from "./autoCompleteLocation";

function KycScreen() {
  const [form] = useForm();
  const { t } = useTranslation();
  const userdetails = useSelector((state: any) => state.auth.user);
  const CurrencyInfo = useSelector((state: any) => state?.currencyInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [kycStatus, setKycStatus] = useState<boolean>(false);
  const [kyc, setKyc] = useState<any>(null);
  const [companyPan, setCompanyPan] = useState<any>("");
  const [vatCertificate, setVatCertificate] = useState<any>("");
  const [authorisedPerson1, setAuthorisedPerson1] = useState<any>("");
  const [location, setLocation] = useState<any>();
  const [authorisedPerson2, setAuthorisedPerson2] = useState<any>("");
  const [submitType, setSubmitType] = useState("submit");
  const [type, setType] = useState<string>("individual");
  const [personalId, setPersonalIdType] = useState<string>("passport");
  const [defaultCountryCode, setDefaultCountryCode] = useState("");

  let userId = userdetails.id;
  let defualtMobile = userdetails?.phoneNumber?.split(" ");

  useEffect(() => {
    getKycDetails();
    fetchUserCountry(CurrencyInfo.currencyInfo)
      .then((res: any) => {
        setDefaultCountryCode(res);
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  }, []);

  useEffect(() => {
    if (defaultCountryCode) {
      form.setFieldsValue({ code: defaultCountryCode });
      form.setFieldsValue({ code1: defaultCountryCode });
      form.setFieldsValue({ code2: defaultCountryCode });
    }
  }, [defaultCountryCode, form]);

  const getKycDetails = async () => {
    try {
      const response: any = await GET(API.GET_KYC_BY_ID + userId, null);
      if (response?.statusCode !== 404) {
        setType(response?.type);
        setKyc(response);
        form.setFieldsValue(response);
        setPersonalIdType(response?.city);
        setSubmitType("update");
        setCompanyPan({ url: response?.companyPan });
        setVatCertificate({ url: response?.vatCertificate });
        setAuthorisedPerson1({ url: response?.authorisedPersonPrimaryImage });
        setAuthorisedPerson2({ url: response?.authorisedPersonSecondaryImage });
        if (
          response?.isissued === statusType.pending ||
          response?.isissued === statusType.accept
        ) {
          setKycStatus(true);
        }
      }
    } catch (error) {
    } finally {
    }
  };

  const imgUpload = async (file: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (file) {
          const formData = new FormData();
          formData.append("file", file.file);
          const response = await fetch(`${API.BASE_URL}${API.IMAGE_UPLOADER}`, {
            method: "POST",
            body: formData,
          });
          if (response.ok) {
            const jsonResponse: any = await response.json();
            const obj = {
              ...jsonResponse,
              url: jsonResponse.Location,
              status: true,
            };
            resolve(obj);
          } else {
            let obj = {
              status: false,
              url: null,
            };
            reject(obj);
          }
        } else {
          reject("no file selected");
        }
      } catch (err) {
        reject(err);
      }
    });
  };
  const onFinish = async (val: KycRequestBody) => {
    if (!location && val.address == null && val.address === undefined) {
      setError(true);
      return;
    }
    setIsLoading(true);

    const Url = submitType === "submit" ? API.ADD_KYC : API.UPDATE_ISSUED;
    const Method = submitType === "submit" ? POST : PUT;
    try {
      const requestBody: any = {
        type: type,
        name: val?.name ? val?.name : "",
        companyName: val?.companyName ? val?.companyName : "",
        mobile: `+${val?.code} ${val?.mobile}`,
        email: val?.email ? val?.email : "",
        address: location ?? "",
        city: personalId,
        pinCode: val?.pinCode,
        vatNumber: val?.vatNumber,
        natureOfBusiness: val?.natureOfBusiness,
        isLogisticServiceRequired: val?.isLogisticServiceRequired,
        primaryPartnerName: val?.primaryPartnerName,
        primaryPartnerMobile: `+${val?.code1} ${
          val?.primaryPartnerMobile || ""
        }`,
        secondaryPartnerName: val?.secondaryPartnerName,
        secondaryPartnerMobile: `+${val?.code2} ${
          val?.secondaryPartnerMobile || ""
        }`,
        bankName: val?.bankName ? val?.bankName : "",
        bankAccountName: val?.bankAccountName ? val?.bankAccountName : "",
        accountNumber: val?.accountNumber ? val?.accountNumber : "",
        branch: val?.branch ? val?.branch : "",
        ifscCode: val?.ifscCode,
        authorisedPersonPrimary: val?.authorisedPersonPrimary,
        authorisedPersonSecondary: val?.authorisedPersonSecondary,
        authorisedPersonTertiary: val?.authorisedPersonTertiary,
        authorisedPersonQuaternary: val?.authorisedPersonQuaternary,
        // companyPan: val?.companyPan,
        vatCertificate: val?.vatCertificate,
        authorisedPersonPrimaryImage: val?.authorisedPersonPrimaryImage,
        authorisedPersonSecondaryImage: val?.authorisedPersonSecondaryImage,
      };
      const imageFile2 = val?.vatCertificate;
      const imageFile3 = val?.authorisedPersonPrimaryImage;
      const imageFile4 = val?.authorisedPersonSecondaryImage;

      if (imageFile2 && val.vatCertificate !== kyc?.vatCertificate) {
        const compressedImage2: any = await imgUpload(imageFile2);
        requestBody.vatCertificate = compressedImage2.url;
      }

      if (
        imageFile3 &&
        val.authorisedPersonPrimaryImage !== kyc?.authorisedPersonPrimaryImage
      ) {
        const compressedImage3: any = await imgUpload(imageFile3);

        requestBody.authorisedPersonPrimaryImage = compressedImage3.url;
      }
      if (
        imageFile4 &&
        val.authorisedPersonSecondaryImage !==
          kyc?.authorisedPersonSecondaryImage
      ) {
        const compressedImage4: any = await imgUpload(imageFile4);
        requestBody.authorisedPersonSecondaryImage = compressedImage4.url;
      }
      const response: any = await Method(Url + userId, requestBody);
      if (response?.status) {
        message.success(
          `KYC ${submitType === "submit" ? "Created" : "Updated"} Succesfully`
        );
        window.location.reload();
        getKycDetails();
      } else {
        message.error(
          `KYC ${
            submitType === "submit" ? "Created" : "Updated"
          } Failed. please try again`
        );
      }
    } catch (err) {
      setIsLoading(false);
      message.error("failed to submit");
    } finally {
      setIsLoading(false);
    }
  };

  const kycStatusTypedescription: any = (text: any, reason?: string) => {
    if (text === statusType.accept) {
      return t("congratulations_kyc_accepted");
    }
    if (text === statusType.pending) {
      return t("your_kyc_pending_review");
    }
    if (text === statusType.reject) {
      return `${t("unfortunately_kyc_rejected")} ${
        reason ? `${t("reason_for_reject_may_be")} ${reason}.` : ""
      } ${t("if_you_need_assistance_or_have_questions")}`;
    }
  };

  const kycStatusType = (text: any) => {
    if (text === statusType.accept) {
      return "success";
    }
    if (text === statusType.pending) {
      return "info";
    }
    if (text === statusType.reject) {
      return "error";
    }
  };

  const selectBefore = (
    <Form.Item
      name="code"
      rules={[{ required: true, message: t("required") }]}
      style={{ margin: 0, height: "5vh" }}
      className="app-KycScreen-Form-Items-SelectBefore"
    >
      <Select
        bordered={false}
        className="app-KycScreen-Form-Items-SelectBefore"
        style={{ width: "100px", color: "white", marginTop: "8px" }}
        options={CurrencyInfo?.currencyInfo.map((item: any) => ({
          value: item.dail_code,
          label: (
            <>
              <img
                alt={item?.country_code}
                src={`https://flagsapi.com/${item?.country_code}/flat/24.png`}
              />
              &nbsp;
              {"+"}
              {item.dail_code}
            </>
          ),
        }))}
      />
    </Form.Item>
  );

  const selectBefore1 = (
    <Form.Item
      name="code1"
      rules={[{ required: true, message: t("required") }]}
      style={{ margin: 0, height: "5vh" }}
      className="app-KycScreen-Form-Items-SelectBefore"
    >
      <Select
        style={{ width: "100px", color: "white" }}
        options={CurrencyInfo?.currencyInfo.map((item: any) => ({
          value: item.dail_code,
          label: (
            <>
              <img
                alt={item?.country_code}
                src={`https://flagsapi.com/${item?.country_code}/flat/24.png`}
              />
              &nbsp;
              {"+"}
              {item.dail_code}
            </>
          ),
        }))}
      />
    </Form.Item>
  );

  const selectBefore2 = (
    <Form.Item
      name="code2"
      rules={[{ required: true, message: t("required") }]}
      style={{ margin: 0, height: "5vh" }}
      className="app-KycScreen-Form-Items-SelectBefore"
    >
      <Select
        style={{ width: "100px", color: "white" }}
        options={CurrencyInfo?.currencyInfo.map((item: any) => ({
          value: item.dail_code,
          label: (
            <>
              <img
                alt={item?.country_code}
                src={`https://flagsapi.com/${item?.country_code}/flat/24.png`}
              />
              &nbsp;
              {"+"}
              {item.dail_code}
            </>
          ),
        }))}
      />
    </Form.Item>
  );

  const handleidTypeChange = (value: string) => {
    setPersonalIdType(value);
  };

  const locationPick = (loca: any) => {
    setLocation(loca?.address);
  };

  return (
    <div>
      <AppPageHeader title={t("kyc_details")} />
      <Container>
        <Form
          onFinish={onFinish}
          layout="vertical"
          form={form}
          disabled={kycStatus}
          scrollToFirstError
        >
          <Row className="app-KycScreen-Box">
            <div>
              {kyc ? (
                <Alert
                  message={
                    kyc?.isissued === statusType.pending
                      ? `KYC is ${kyc?.isissued}`
                      : `KYC is ${kyc?.isissued}`
                  }
                  description={kycStatusTypedescription(
                    kyc?.isissued,
                    kyc?.reasonreject
                  )}
                  type={kycStatusType(kyc?.isissued)}
                  showIcon
                  style={{ marginBottom: 20, fontSize: "18px" }}
                />
              ) : null}
            </div>
            <Col md={12}>
              {kycStatus ? (
                <></>
              ) : (
                <Form.Item
                  name={"type"}
                  label={
                    <label className="app-KycScreen-Form-Txt2">
                      {t("type")}
                    </label>
                  }
                  rules={[
                    {
                      required: true,
                      message: t("this_field_is_required"),
                    },
                  ]}
                >
                  <Radio.Group
                    name="radiogroup"
                    className="d-flex justify-content-start"
                    onChange={(e: any) => setType(e.target.value)}
                    value={type}
                  >
                    <Radio style={{ color: "#fff" }} value={"individual"}>
                      {t("individual")}
                    </Radio>
                    <Radio style={{ color: "#fff" }} value={"company"}>
                      {t("company")}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              )}
            </Col>
            <Col md={12}>
              <div className="app-KycScreen-Form-Txt1">
                {type === "company"
                  ? t("company_details")
                  : t("individual_details")}
              </div>
            </Col>
            <Col md={6}>
              {type === "company" ? (
                <Form.Item
                  initialValue={userdetails.name}
                  className="app-KycScreen-Form-Items"
                  name={"name"}
                  label={
                    <label className="app-KycScreen-Form-Txt2">
                      {t("fullname")}:
                    </label>
                  }
                  rules={[
                    {
                      required: true,
                      message: t("this_field_is_required"),
                    },
                  ]}
                >
                  <Input placeholder="Name" className="inputstyle" />
                </Form.Item>
              ) : (
                <Form.Item
                  initialValue={userdetails.name}
                  className="app-KycScreen-Form-Items"
                  name={"name"}
                  label={
                    <label className="app-KycScreen-Form-Txt2">
                      {t("fullname")}:
                    </label>
                  }
                  rules={[
                    {
                      required: true,
                      message: t("this_field_is_required"),
                    },
                    {
                      pattern: /^[a-zA-Z\s]+$/, // Allows only alphabets and spaces
                      message: t("please_enter_valid_name"),
                    },
                  ]}
                >
                  <Input placeholder="Name" className="inputstyle" />
                </Form.Item>
              )}
            </Col>
            <Col md={6}>
              <Form.Item
                className="app-KycScreen-Form-Items"
                initialValue={defualtMobile?.[1]}
                name={"mobile"}
                label={
                  <label className="app-KycScreen-Form-Txt2">
                    {t("phone_number")} :{" "}
                  </label>
                }
                rules={[
                  {
                    required: true,
                    message: t("Phone_number_is_required"),
                  },
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.resolve();
                      }
                      if (!/^[0-9]{6,10}$/.test(value)) {
                        return Promise.reject(
                          t("Please_enter_a_valid_phone_number")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  addonBefore={selectBefore}
                  placeholder={t("enter_phone_number")}
                  className="inputstyle"
                />
              </Form.Item>
            </Col>

            <Col md={6}>
              <label
                className="app-KycScreen-Form-Txt2"
                style={{ marginBottom: "8px" }}
              >
                {t("pin_code")} :{" "}
              </label>
              <Form.Item
                className="app-KycScreen-Form-Items"
                name={"pinCode"}
                rules={[
                  {
                    pattern: /^[\d\s]+$/, // Allows only numeric digits and spaces
                    message: t("please_enter_valid_number"),
                  },
                  {
                    max: 10,
                    message: t("number_length_must_be_less_than_10"),
                  },
                ]}
              >
                <Input
                  placeholder={t("enter_pin_code")}
                  className="inputstyle"
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                className="app-KycScreen-Form-Items"
                initialValue={userdetails.email}
                name={"email"}
                label={
                  <label className="app-KycScreen-Form-Txt2">
                    {type === "company" ? t("companyemail") : t("email")}
                  </label>
                }
                rules={[
                  {
                    required: true,
                    message: t("this_field_is_required"),
                  },
                  {
                    type: "email",
                    message: t("please_enter_valid_email"),
                  },
                ]}
              >
                <Input
                  placeholder={t("enter_email")}
                  type="email"
                  className="inputstyle"
                />
              </Form.Item>
            </Col>
            {kycStatus ? (
              <>
                <Form.Item
                  className="app-KycScreen-Form-Items"
                  name={"address"}
                  label={
                    <label className="app-KycScreen-Form-Txt2">
                      {t("address")}
                    </label>
                  }
                >
                  <Input className="inputstyle" />
                </Form.Item>
              </>
            ) : (
              <Col md={6}>
                <Form.Item
                  className="app-KycScreen-Form-Items"
                  name={"address"}
                  label={
                    <label className="app-KycScreen-Form-Txt2">
                      {t("address")}
                    </label>
                  }
                  required
                >
                  <AutoCompleteLocation
                    location={location}
                    setCurrentLocation={(value: any) => locationPick(value)}
                  />
                </Form.Item>
                {error && (
                  <div style={{ color: "red" }}>
                    {t("this_field_is_required")}
                  </div>
                )}
              </Col>
            )}
            {type === "company" && (
              <>
                <Col md={6}>
                  <Form.Item
                    className="app-KycScreen-Form-Items"
                    name={"natureOfBusiness"}
                    label={
                      <label className="app-KycScreen-Form-Txt2">
                        {t("company_registration_number")} :
                      </label>
                    }
                    rules={[
                      {
                        required: true,
                        message: t("this_field_is_required"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("enter_company_registration_number")}
                      className="inputstyle"
                    />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item
                    className="app-KycScreen-Form-Items"
                    name={"companyName"}
                    label={
                      <label className="app-KycScreen-Form-Txt2">
                        {t("company_name")} :
                      </label>
                    }
                    rules={[
                      {
                        required: true,
                        message: t("this_field_is_required"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("company_name")}
                      className="inputstyle"
                    />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item
                    className="app-KycScreen-Form-Items"
                    name={"vatNumber"}
                    label={
                      <label className="app-KycScreen-Form-Txt2">
                        {t("vat_no")} :
                      </label>
                    }
                    rules={[
                      {
                        required: true,
                        message: t("this_field_is_required"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("enter_vat_no")}
                      className="inputstyle"
                    />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    name={"isLogisticServiceRequired"}
                    label={
                      <label className="app-KycScreen-Form-Txt2">
                        {t("is_logistics_service_required")}
                      </label>
                    }
                    rules={[
                      {
                        required: true,
                        message: t("select_option"),
                      },
                    ]}
                  >
                    <Radio.Group
                      name="radiogroup"
                      className="d-flex justify-content-start"
                    >
                      <Radio value={true} className="app-KycScreen-Form-Txt2">
                        {t("yes")}
                      </Radio>
                      <Radio value={false} className="app-KycScreen-Form-Txt2">
                        {t("no")}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </>
            )}
            <Col md={12}>
              <div className="app-KycScreen-Form-Txt1">{t("bank_details")}</div>
            </Col>
            <Col md={6}>
              <label
                className="app-KycScreen-Form-Txt2"
                style={{ marginBottom: "8px" }}
              >
                {t("bank_name")} :
              </label>
              <Form.Item
                className="app-KycScreen-Form-Items"
                name={"bankName"}
                rules={[
                  {
                    pattern: /^[a-zA-Z\s]+$/, // Allows only alphabets and spaces
                    message: t("please_enter_valid_name"),
                  },
                ]}
              >
                <Input
                  placeholder={t("enter_bank_name")}
                  className="inputstyle"
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <label
                className="app-KycScreen-Form-Txt2"
                style={{ marginBottom: "8px" }}
              >
                {t("bank_account_name")}:
              </label>
              <Form.Item
                className="app-KycScreen-Form-Items"
                name={"bankAccountName"}
              >
                <Input
                  placeholder={t("bank_account_name")}
                  className="inputstyle"
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <label
                className="app-KycScreen-Form-Txt2"
                style={{ marginBottom: "8px" }}
              >
                {t("account_no")} :
              </label>
              <Form.Item
                className="app-KycScreen-Form-Items"
                name={"accountNumber"}
              >
                <Input placeholder={t("account_no")} className="inputstyle" />
              </Form.Item>
            </Col>
            <Col md={6}>
              <label
                className="app-KycScreen-Form-Txt2"
                style={{ marginBottom: "8px" }}
              >
                {t("ifsc_code")} :
              </label>
              <Form.Item className="app-KycScreen-Form-Items" name={"ifscCode"}>
                <Input
                  placeholder={t("enter_ifsc_code")}
                  className="inputstyle"
                  style={{ textTransform: "uppercase" }}
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <label
                className="app-KycScreen-Form-Txt2"
                style={{ marginBottom: "8px" }}
              >
                {t("branch")}:
              </label>
              <Form.Item className="app-KycScreen-Form-Items" name={"branch"}>
                <Input placeholder={t("enter_branch")} className="inputstyle" />
              </Form.Item>
            </Col>
            <Col md={12}>
              <div className="app-KycScreen-Form-Txt1">
                {t("persons_authorized_for_delivery")}
              </div>
            </Col>
            <Col md={6}>
              <label
                className="app-KycScreen-Form-Txt2"
                style={{ marginBottom: "8px" }}
              >
                {t("name")} 1 :
              </label>
              <Form.Item
                className="app-KycScreen-Form-Items"
                name={"authorisedPersonPrimary"}
                rules={[
                  {
                    pattern: /^[a-zA-Z\s]+$/, // Allows only alphabets and spaces
                    message: t("please_enter_valid_name"),
                  },
                ]}
              >
                <Input
                  placeholder={t("enter_authorised_person")}
                  className="inputstyle"
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <label
                className="app-KycScreen-Form-Txt2"
                style={{ marginBottom: "8px" }}
              >
                {t("name")} 2 :
              </label>
              <Form.Item
                className="app-KycScreen-Form-Items"
                name={"authorisedPersonSecondary"}
                rules={[
                  {
                    pattern: /^[a-zA-Z\s]+$/, // Allows only alphabets and spaces
                    message: t("please_enter_valid_name"),
                  },
                ]}
              >
                <Input
                  placeholder={t("enter_authorised_person")}
                  className="inputstyle"
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <label
                className="app-KycScreen-Form-Txt2"
                style={{ marginBottom: "8px" }}
              >
                {t("name")} 3 :
              </label>
              <Form.Item
                className="app-KycScreen-Form-Items"
                name={"authorisedPersonTertiary"}
                rules={[
                  {
                    pattern: /^[a-zA-Z\s]+$/, // Allows only alphabets and spaces
                    message: t("please_enter_valid_name"),
                  },
                ]}
              >
                <Input
                  placeholder={t("enter_authorised_person")}
                  className="inputstyle"
                />
              </Form.Item>
            </Col>
            <Col md={6}>
              <label
                className="app-KycScreen-Form-Txt2"
                style={{ marginBottom: "8px" }}
              >
                {t("name")} 4 :
              </label>
              <Form.Item
                className="app-KycScreen-Form-Items"
                name={"authorisedPersonQuaternary"}
                rules={[
                  {
                    pattern: /^[a-zA-Z\s]+$/, // Allows only alphabets and spaces
                    message: t("please_enter_valid_name"),
                  },
                ]}
              >
                <Input
                  placeholder={t("enter_authorised_person")}
                  className="inputstyle"
                />
              </Form.Item>
            </Col>
            {type === "company" ? (
              <>
                <Col md={12}>
                  <div className="app-KycScreen-Form-Txt1">
                    {t("upload_authorized_persons_id_as_image")}
                  </div>
                </Col>
                {kycStatus ? (
                  <Col md={6}>
                    <Form.Item
                      className="app-KycScreen-Form-Items"
                      name={"authorisedPersonPrimaryImage"}
                      label={
                        <label className="app-KycScreen-Form-Txt2">
                          {t("front_side")}
                        </label>
                      }
                    >
                      <div className="authorisedPersonPrimaryImage">
                        <img src={vatCertificate?.url} />
                      </div>
                    </Form.Item>
                  </Col>
                ) : (
                  <Row>
                    <Col md={6}>
                      <Form.Item
                        className="app-KycScreen-Form-Items"
                        name={"vatCertificate"}
                        label={
                          <label className="app-KycScreen-Form-Txt2">
                            {t("Company_certificate")}
                          </label>
                        }
                        rules={[
                          {
                            required: true,
                            message: t("please_upload_image"),
                          },
                        ]}
                      >
                        <ImagePicker
                          onChange={(file: any) => {
                            setVatCertificate(file);
                            setCropModalOpen(true);
                          }}
                          fileURL={vatCertificate?.url}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              <Row>
                <Col md={12}>
                  <div className="app-KycScreen-Form-Txt1">
                    {t("upload_your_document")}
                  </div>
                </Col>
                <Col md={12}>
                  <div className="app-KycScreen-Form-Txt2">
                    {t("Choose_Document_Type")}
                  </div>
                  <br />
                  {kycStatus ? (
                    <>
                      <div
                        className={`app-KycScreen-Form-choose-Id1 active `}
                        style={{ width: "150px" }}
                      >
                        {kyc.city}
                      </div>
                      <br />
                    </>
                  ) : (
                    <div className="app-KycScreen-Form-choose-Id">
                      <div
                        className={`app-KycScreen-Form-choose-Id1 ${
                          personalId === "passport" ? "active" : ""
                        } `}
                        onClick={() => handleidTypeChange("passport")}
                      >
                        Passport
                      </div>
                      <div
                        className={`app-KycScreen-Form-choose-Id1 ${
                          personalId === "nationalId" ? "active" : ""
                        }`}
                        onClick={() => handleidTypeChange("nationalId")}
                      >
                        National id
                      </div>
                    </div>
                  )}
                </Col>
                {kycStatus && personalId === "passport" ? (
                  <>
                    <Col md={6}>
                      <Form.Item
                        className="app-KycScreen-Form-Items"
                        name={"authorisedPersonPrimaryImage"}
                        label={
                          <label className="app-KycScreen-Form-Txt2">
                            {t("front_side")}
                          </label>
                        }
                      >
                        <div className="authorisedPersonPrimaryImage">
                          <img src={authorisedPerson1?.url} />
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                ) : personalId === "passport" ? (
                  <Col md={6}>
                    <Form.Item
                      className="app-KycScreen-Form-Items"
                      name={"authorisedPersonPrimaryImage"}
                      label={
                        <label className="app-KycScreen-Form-Txt2">
                          {t("front_side")}
                        </label>
                      }
                      rules={[
                        {
                          required: true,
                          message: t("please_upload_image"),
                        },
                      ]}
                    >
                      <ImagePicker
                        onChange={(file: any) => {
                          setAuthorisedPerson1(file);
                          setCropModalOpen(true);
                        }}
                        fileURL={authorisedPerson1?.url}
                      />
                    </Form.Item>
                  </Col>
                ) : kycStatus && personalId === "nationalId" ? (
                  <>
                    <Col md={6}>
                      <Form.Item
                        className="app-KycScreen-Form-Items"
                        name={"authorisedPersonPrimaryImage"}
                        label={
                          <label className="app-KycScreen-Form-Txt2">
                            {t("front_side")}
                          </label>
                        }
                      >
                        <div className="authorisedPersonPrimaryImage">
                          <img src={authorisedPerson1?.url} />
                        </div>
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        className="app-KycScreen-Form-Items"
                        name={"authorisedPersonSecondaryImage"}
                        label={
                          <label className="app-KycScreen-Form-Txt2">
                            {t("back_side")}
                          </label>
                        }
                      >
                        <div className="authorisedPersonPrimaryImage">
                          <img src={authorisedPerson2?.url} alt="no image" />
                        </div>
                      </Form.Item>
                    </Col>
                  </>
                ) : personalId === "nationalId" ? (
                  <>
                    <Col md={6}>
                      <Form.Item
                        className="app-KycScreen-Form-Items"
                        name={"authorisedPersonPrimaryImage"}
                        label={
                          <label className="app-KycScreen-Form-Txt2">
                            {t("front_side")}
                          </label>
                        }
                        rules={[
                          {
                            required: true,
                            message: t("please_upload_image"),
                          },
                        ]}
                      >
                        <ImagePicker
                          onChange={(file: any) => {
                            setAuthorisedPerson1(file);
                            setCropModalOpen(true);
                          }}
                          fileURL={authorisedPerson1?.url}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        className="app-KycScreen-Form-Items"
                        name={"authorisedPersonSecondaryImage"}
                        label={
                          <label className="app-KycScreen-Form-Txt2">
                            {t("back_side")}
                          </label>
                        }
                        rules={[
                          {
                            required: true,
                            message: t("please_upload_image"),
                          },
                        ]}
                      >
                        <ImagePicker
                          onChange={(file: any) => {
                            setAuthorisedPerson2(file);
                            setCropModalOpen(true);
                          }}
                          fileURL={authorisedPerson2?.url}
                        />
                      </Form.Item>
                    </Col>
                  </>
                ) : null}
              </Row>
            )}
            <Row>
              <Col md={12} className="d-flex justify-content-center">
                {!kycStatus ? (
                  <Form.Item>
                    <Button
                      loading={isLoading}
                      htmlType="submit"
                      className="app-KycScreen-Box-Button"
                    >
                      {submitType === "submit" ? t("submit") : t("update")}{" "}
                      {t("form")}
                    </Button>
                  </Form.Item>
                ) : null}
              </Col>
            </Row>
          </Row>
        </Form>
      </Container>
    </div>
  );
}

export default KycScreen;
