import { BsGrid1X2 } from "react-icons/bs";
import { FaWpforms } from "react-icons/fa6";
import { GrCatalogOption, GrCurrency, GrLineChart } from "react-icons/gr";
import {
  IoBag,
  IoBasketOutline,
  IoLogOutOutline,
  IoPeopleOutline,
  IoWalletOutline,
} from "react-icons/io5";
import {
  MdOutlineVerifiedUser,
  MdProductionQuantityLimits,
  MdRedeem,
} from "react-icons/md";
import { PiTable } from "react-icons/pi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Logo from "../../assets/images/logo-2.png";
import { useHandleTokenExpiration } from "../../components/CommonHeader/helpers/fetchingDatas";

function SideBar(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const current = window.location.pathname;

  // const logout = () => {
  //   dispatch(isLoggedOut());
  //   navigate("/");
  // };
  const handleTokenExpiration = useHandleTokenExpiration();

  return (
    <div className="AdminRoutes-sideBar">
      <div className="AdminRoutes-sideBarBox1">
        <img src={Logo} alt="Logo" />
      </div>
      <div
        className={`AdminRoutes-sideBarItem  ${
          current === "/admin/dashboard" ? "nav-active" : ""
        }`}
        onClick={() => navigate("/admin/dashboard")}
      >
        <div>
          <BsGrid1X2 size={20} />
        </div>
        <div className="AdminRoutes-sideBartxt1">Dashboard</div>
      </div>
      <div
        className={`AdminRoutes-sideBarItem  ${
          current === "/admin/customer" ? "nav-active" : ""
        }`}
        onClick={() => navigate("/admin/customer")}
      >
        <div>
          <IoPeopleOutline size={30} />
        </div>
        <div className="AdminRoutes-sideBartxt1">Customers</div>
      </div>
      <div
        className={`AdminRoutes-sideBarItem  ${
          current === "/admin/trading" ? "nav-active" : ""
        }`}
        onClick={() => navigate("/admin/trading")}
      >
        <div>
          <IoBasketOutline size={25} />
        </div>
        <div className="AdminRoutes-sideBartxt1">Trading</div>
      </div>
      <div
        className={`AdminRoutes-sideBarItem  ${
          current === "/admin/wallet" ? "nav-active" : ""
        }`}
        onClick={() => navigate("/admin/wallet")}
      >
        <div>
          <IoWalletOutline size={25} />
        </div>
        <div className="AdminRoutes-sideBartxt1">Wallet</div>
      </div>
      <div
        className={`AdminRoutes-sideBarItem  ${
          current === "/admin/orders" ? "nav-active" : ""
        }`}
        onClick={() => navigate("/admin/orders")}
      >
        <div>
          <IoBag size={25} />
        </div>
        <div className="AdminRoutes-sideBartxt1">Orders</div>
      </div>
      <div
        className={`AdminRoutes-sideBarItem  ${
          current === "/admin/products" ? "nav-active" : ""
        }`}
        onClick={() => navigate("/admin/products")}
      >
        <div>
          <MdProductionQuantityLimits size={25} />
        </div>
        <div className="AdminRoutes-sideBartxt1">Products</div>
      </div>
      <div
        className={`AdminRoutes-sideBarItem  ${
          current === "/admin/sip" ? "nav-active" : ""
        }`}
        onClick={() => navigate("/admin/sip")}
      >
        <div>
          <GrLineChart size={25} />
        </div>
        <div className="AdminRoutes-sideBartxt1">SIP</div>
      </div>
      <div
        className={`AdminRoutes-sideBarItem  ${
          current === "/admin/redeemrequest" ? "nav-active" : ""
        }`}
        onClick={() => navigate("/admin/redeemrequest")}
      >
        <div>
          <MdRedeem size={25} />
        </div>
        <div className="AdminRoutes-sideBartxt1">Redeem</div>
      </div>
      <div
        className={`AdminRoutes-sideBarItem  ${
          current === "/admin/kycVerification" ? "nav-active" : ""
        }`}
        onClick={() => navigate("/admin/kycVerification")}
      >
        <div>
          <MdOutlineVerifiedUser size={25} />
        </div>
        <div className="AdminRoutes-sideBartxt1">KYC verify</div>
      </div>
      <div
        className={`AdminRoutes-sideBarItem  ${
          current === "/admin/script" ? "nav-active" : ""
        }`}
        onClick={() => navigate("/admin/script")}
      >
        <div>
          <PiTable size={25} />
        </div>
        <div className="AdminRoutes-sideBartxt1">Script</div>
      </div>
      <div
        className={`AdminRoutes-sideBarItem  ${
          current === "/admin/enquiries" ? "nav-active" : ""
        }`}
        onClick={() => navigate("/admin/enquiries")}
      >
        <div>
          <FaWpforms size={25} />
        </div>
        <div className="AdminRoutes-sideBartxt1">Enquiries</div>
      </div>
      <div
        className={`AdminRoutes-sideBarItem  ${
          current === "/admin/currency" ? "nav-active" : ""
        }`}
        onClick={() => navigate("/admin/currency")}
      >
        <div>
          <GrCurrency size={20} />
        </div>
        <div className="AdminRoutes-sideBartxt1">Currency</div>
      </div>
      <div
        className={`AdminRoutes-sideBarItem  ${
          current === "/admin/category" ? "nav-active" : ""
        }`}
        onClick={() => navigate("/admin/category")}
      >
        <div>
          <GrCatalogOption size={20} />
        </div>
        <div className="AdminRoutes-sideBartxt1">Category</div>
      </div>
      <div style={{ flex: 1 }} />
      <div className="AdminRoutes-sideBarItem" onClick={handleTokenExpiration}>
        <div>
          <IoLogOutOutline size={25} color="red" />
        </div>
        <div className="AdminRoutes-sideBartxt1">Logout</div>
      </div>
    </div>
  );
}

export default SideBar;
