import Menu from "./menu.json";
import { useLocation, useNavigate } from "react-router-dom";
import DynamicIcon from "./dynamicIcons";
import Logo from "../../assets/images/logo-2.png";
import { RiLogoutCircleRLine } from "react-icons/ri";

import LanguageSelector from "../../components/websiteHeader/languageSelector";
import { useHandleTokenExpiration } from "../../components/CommonHeader/helpers/fetchingDatas";
import { useTranslation } from "react-i18next";

function SiderScreen(props: any) {
  const { t } = useTranslation();
  const handleTokenExpiration = useHandleTokenExpiration();

  const navigate = useNavigate();
  let location = useLocation();

  const handleClick = (val: any) => {
    navigate(val);
    props?.onChange();
  };

  return (
    <div className="app-sideBar-Box">
      <div>
        <div className="app-sideBar-logoBox" onClick={() => navigate("/Auth")}>
          <img src={Logo} />
          <div>
            LSG <span className="app-sideBar-text1">Digital Gold</span>
          </div>
        </div>
        <div className="app-sideBar-item-mobile">
          <LanguageSelector />
        </div>
        <div>
          {Menu?.map((item: any, index: any) => {
            return (
              <div
                key={item?.id}
                onClick={() => handleClick(item?.navigate)}
                className={`app-sideBar-item ${
                  location.pathname === item?.navigate ? "active" : ""
                }`}
              >
                <DynamicIcon icon={item?.icon} color={"#ffffffee"} />
                <div style={{ marginLeft: 20 }} />
                <div>{t(item?.title)}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <div
          className={"app-sideBar-item"}
          style={{ color: "red", marginTop: "" }}
          onClick={handleTokenExpiration}
        >
          <RiLogoutCircleRLine color="red" size={20} />
          <div style={{ marginLeft: 20 }} />
          <div>{t("logout")}</div>
        </div>
        <br />
        <div className="app-sideBar-brand-text">Powerd By Semikolen</div>
      </div>
    </div>
  );
}

export default SiderScreen;
