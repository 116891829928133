import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, message, notification, Upload } from "antd";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile } from "antd/es/upload/interface";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../../../config/api";
import { COMPRESS_IMAGE, GET, POST, PUT } from "../../../../utlis/apiCalls";
import PageHeader from "../../../routes/pageHeader";

interface ProductFormValues {
  name: string;
  desc: string;
  image: string;
}

const { TextArea } = Input;

const CategoryForm: React.FC = () => {
  const param: any = useParams();
  const [form] = Form.useForm();
  const [notificationApi, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const id = param.id;

  const [submitting, setSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState<RcFile | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id !== "create") {
      fechCategories();
    }
  }, []);

  const fechCategories = async () => {
    try {
      setIsLoading(true);
      const response: any = await GET(API.CATEGORY + "/" + id);
      const categoryData = response.data;
      if (response?.status) {
        form.setFieldsValue({
          ...categoryData,
        });

        if (categoryData.image) {
          setPreviewUrl(categoryData.image);
          setSelectedFile(categoryData.image);
        }
      }
    } catch (error) {
      notificationApi.error({
        message: "Error",
        description: "Failed to fetch categories",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG files!");
      return false;
    }
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      return false;
    }
    setSelectedFile(file);
    const preview = URL.createObjectURL(file);
    setPreviewUrl(preview);
    return false;
  };

  const handleChange = (info: UploadChangeParam<UploadFile>) => {
    if (!info.file) {
      setSelectedFile(null);
      setPreviewUrl(null);
    }
  };

  const uploadToS3 = async (file: RcFile): Promise<string> => {
    try {
      // Create a new file with timestamp name
      const fileName = `${moment().unix()}_${file.name}`;
      const imageBlob = await fetch(URL.createObjectURL(file)).then((r) =>
        r.blob()
      );
      const processedFile = new File([imageBlob], fileName, {
        type: imageBlob.type,
      });
      const response: any = await COMPRESS_IMAGE(processedFile);
      if (response && response.status && response.url) {
        return response.url;
      } else {
        notificationApi.error({
          message: "Error",
          description: response.message || "Failed to create product",
        });
        throw new Error(response?.message || "Image upload failed");
      }
    } catch (error: any) {
      throw new Error(`Failed to upload image: ${error.message}`);
    }
  };

  const handleSubmit = async (values: ProductFormValues) => {
    try {
      setSubmitting(true);
      if (!selectedFile && !previewUrl) {
        message.error("Please select an image");
        return;
      }
      let finalValues = { ...values };
      if (selectedFile?.uid) {
        const imageUrl = await uploadToS3(selectedFile);
        finalValues = {
          ...values,
          image: imageUrl,
        };
      }
      const METHOD = id === "create" ? POST : PUT;
      const API_URL =
        id === "create" ? API.CATEGORY_CREATE : `${API.CATEGORY}/${id}`;
      const res: any = await METHOD(API_URL, finalValues);
      if (res.status) {
        message.success(
          `Category ${id === "create" ? "created" : "updated"} successfully!`
        );
        form.resetFields();
        setSelectedFile(null);
        setPreviewUrl(null);
        navigate("/admin/category");
      } else {
        notificationApi.error({
          message: "Error",
          description:
            res.message ||
            `Failed to ${id === "create" ? "create" : "update"} category`,
        });
      }
    } catch (error: any) {
      notificationApi.error({
        message: "Error",
        description:
          error.message ||
          `Failed to ${id === "create" ? "create" : "update"} category`,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      {contextHolder}
      <br />
      <Container fluid={false}>
        <PageHeader title="Create Category" />
        <Card className="max-w-2xl mx-auto">
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              name="name"
              label="Product Name"
              rules={[
                { required: true, message: "Please enter product name" },
                { min: 2, message: "Name must be at least 2 characters" },
                { whitespace: true, message: "Name cannot be empty spaces" },
              ]}
            >
              <Input
                placeholder="Enter product name (e.g., PAMP)"
                size="large"
                maxLength={100}
              />
            </Form.Item>

            <Form.Item
              name="desc"
              label="Description"
              rules={[
                { required: true, message: "Please enter product description" },
                {
                  min: 20,
                  message: "Description must be at least 20 characters",
                },
                {
                  whitespace: true,
                  message: "Description cannot be empty spaces",
                },
              ]}
            >
              <TextArea
                rows={4}
                placeholder="Enter detailed product description"
                size="large"
                maxLength={500}
                showCount
              />
            </Form.Item>

            <Form.Item
              name="image"
              label="Product Image"
              rules={[{ required: true, message: "Please select an image" }]}
              extra="Select a JPG/PNG file (max 2MB). Image will be uploaded when you submit the form."
            >
              <Upload
                name="image"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                {previewUrl ? (
                  <img
                    src={previewUrl}
                    alt="preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>

            <Form.Item>
              <div className="flex justify-end gap-4">
                <Button
                  onClick={() => {
                    form.resetFields();
                    setSelectedFile(null);
                    setPreviewUrl(null);
                  }}
                  size="large"
                  disabled={submitting}
                >
                  Reset
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={submitting}
                  size="large"
                >
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </Container>
    </>
  );
};

export default CategoryForm;
