import { Button, Input, notification, Pagination } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { IoIosClose } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import API from "../../../config/api";
import useDebounce from "../../../shared/helpers/useDebounce";
import { DELETE, GET } from "../../../utlis/apiCalls";
import PageHeader from "../../routes/pageHeader";
import DataTable from "./components/dataTable";

interface PageMetaType {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

function Categories() {
  const [notificationApi, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<PageMetaType>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [take, setTake] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoading2, setIsLoading2] = useState<boolean>(false);
  const [products, setProducts] = useState([]);
  const [date, setDate] = useState({
    fromDate: "",
    toDate: "",
  });

  const debouncedSearch = useDebounce(searchValue, 1500);

  useEffect(() => {
    getAllCategories();
  }, [page, take, debouncedSearch, date.fromDate, date.toDate]);

  const getAllCategories = async () => {
    try {
      setIsLoading(true);
      const queryParams = new URLSearchParams({
        order: "DESC",
        page: page.toString(),
        take: take.toString(),
        search: searchValue,
        ...(date.fromDate && { fromDate: date.fromDate }),
        ...(date.toDate && { toDate: date.toDate }),
      });

      const response: any = await GET(`${API.CATEGORY_ALL}?${queryParams}`);

      if (response?.status) {
        setProducts(response.data.data);
        setMeta(response.data.meta);
      } else {
        setProducts([]);
        setMeta(undefined);
      }
    } catch (error) {
      notificationApi.error({
        message: "Error",
        description: "Failed to fetch categories",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setPage(1); // Reset to first page on new search
  };

  const handleRefresh = () => {
    setSearchValue("");
    setPage(1);
    setDate({ fromDate: "", toDate: "" });
    getAllCategories();
  };

  const DateChange = (_: any, dateStrings: [string, string]) => {
    setDate({
      fromDate: dateStrings[0],
      toDate: dateStrings[1],
    });
    setPage(1); // Reset to first page on date change
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const handlePaginationChange = (newPage: number, pageSize: number) => {
    window.scrollTo(0, 0);
    setPage(newPage);
    setTake(pageSize);
  };

  const deleteCategory = async (id: number) => {
    try {
      setIsLoading2(true);
      const response: any = await DELETE(`${API.CATEGORY}/${id}`);
      if (response?.status) {
        getAllCategories();
        notificationApi.success({
          message: "Success",
          description: response.message || "Category deleted successfully",
        });
      } else {
        notificationApi.error({
          message: "Error",
          description: response.message || "Failed to delete category",
        });
      }
    } catch (error: any) {
      notificationApi.error({
        message: "Error",
        description: error?.message || "Failed to delete category",
      });
    } finally {
      setIsLoading2(false);
    }
  };

  return (
    <div>
      {contextHolder}
      <br />
      <Container fluid={false}>
        <PageHeader title="Categories">
          <div className="d-flex">
            {/* <RangePicker
              onChange={DateChange}
              disabledDate={disabledDate}
              value={
                date.fromDate && date.toDate
                  ? [dayjs(date.fromDate), dayjs(date.toDate)]
                  : null
              }
            /> */}
            <div style={{ width: 20 }} />
            <Input
              style={{ width: "200px" }}
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
              prefix={<IoSearchOutline size={20} color="gray" />}
              placeholder="Search here.."
              suffix={
                searchValue && (
                  <IoIosClose
                    size={20}
                    color="grey"
                    cursor="pointer"
                    onClick={() => handleSearch("")}
                  />
                )
              }
            />
            <div style={{ width: 20 }} />
            <Button type="primary" onClick={handleRefresh}>
              Refresh
            </Button>
            <Button
              type="primary"
              className="ml-4"
              onClick={() => navigate("/admin/category/create")}
            >
              Create
            </Button>
          </div>
        </PageHeader>
        <div className="overFlow-Box1">
          <DataTable
            isLoading={isLoading}
            allTrade={products}
            isLoading2={isLoading2}
            deleteCategory={(id: number) => deleteCategory(id)}
          />
          {meta && (
            <Pagination
              className="mt-4 text-center"
              current={page}
              total={meta.itemCount}
              pageSize={take}
              showSizeChanger
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              pageSizeOptions={[3, 10, 20, 50]}
              onChange={handlePaginationChange}
            />
          )}
        </div>
      </Container>
    </div>
  );
}

export default Categories;
