import "./styles.scss";

import { useEffect, useState } from "react";

import { Button, notification } from "antd";
import { Container } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import API from "../../../config/api";
import { GET, PUT } from "../../../utlis/apiCalls";
import DataList from "./components/dataList";

export default function NotificationScreen(props: any) {
  const [notificationApi, contextHolder] = notification.useNotification();
  const [notificationAll, setNotificationAll] = useState<any>();
  const { t } = useTranslation();
  const User = useSelector((state: any) => state?.auth?.user);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isLoading2, setIsLoading2] = useState<boolean>();
  const [meta, setMeta] = useState<any>();
  const [pageNo, setPageNo] = useState<any>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [notCount, setNotCount] = useState<number>();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getNotification();
  }, [pageNo, pageSize]);
  const getNotification = async () => {
    try {
      setIsLoading(true);
      const response: any = await GET(
        API.NOTIFICATION_ALL +
          User?.id +
          `?order=DESC&page=${pageNo}&take=${pageSize}`,
        null
      );
      if (response?.status) {
        setNotificationAll(response?.data?.data);
        setMeta(response?.data?.meta);
      }
      const unreadCount = response?.data?.data.reduce(
        (counts: number, item: any) => {
          return !item?.read ? counts + 1 : counts;
        },
        0
      );
      setNotCount(unreadCount);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleReadAll = async () => {
    let obj = {
      user_id: User?.id,
    };
    try {
      setIsLoading2(false);
      const response: any = await PUT(API.NOTIFICATION_UPATE_ALL, obj);
      if (response?.status) {
        getNotification();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading2(false);
    }
  };

  const handlePageSize = (current: number, page: number) => {
    setPageNo(current);
    setPageSize(page);
  };
  return (
    <div className="app-NotificationScreen ">
      {contextHolder}
      <br />
      <Container>
        {" "}
        <div className="app-NotificationScreen-box1">
          <div>
            <strong className="app-NotificationScreen-Txt1">
              {t("notifications")}
            </strong>
            <br />
            <span className="app-NotificationScreen-Txt2">
              {`You have ${
                notCount
                  ? notCount > 5
                    ? "5+ unread notifications"
                    : `${notCount} unread notifications`
                  : "already read all notifications"
              }`}
            </span>
          </div>
          <div style={{ flex: 1 }}></div>
          <Button
            className="app-NotificationScreen-Button"
            onClick={() => handleReadAll()}
            loading={isLoading2}
          >
            {t("mark_all_as_read")}
          </Button>
        </div>
        <DataList
          isLoading={isLoading}
          data={notificationAll}
          meta={meta}
          page={pageNo}
          pageSize={pageSize}
          handlePageSize={handlePageSize}
          refresh={() => getNotification()}
          handlePageChange={(page: any) => setPageNo(page)}
        />
      </Container>
    </div>
  );
}
